import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import WarningIcon from '@mui/icons-material/Warning'
import { styled } from '@mui/material/styles'
import { getLocalStorage, supportEmail } from '../../login/utils.js'
import { getSegments, getH3, deleteSegments, deleteH3 } from '../../DataApi.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { Sources } from '../../constants/Sources'
import normalize from '@mapbox/geojson-normalize'
import { Modes } from '../datasets/DatasetsAnalysis'
import { setH3 } from '../../../reducers/h3'
import { setSegments } from '../../../reducers/segments'
import { addJobs, isAnalysisOnPartiallyDeletedMeasurementsForMode } from '../../../reducers/jobs'
import { overviewDataFromH3 } from '../../map/MapBoxHelpers'
import { LocalStorage } from '../../constants/LocalStorage.js'
import { Status } from '../../JobHelpers.js'
import { useSnackbarContext } from '../../SnackbarContext.js'
import { updateInfrastructureView } from '../../../reducers/infrastructureView.js'

/**
 * This function component handles deleting processed surfaces.
 */
const AnalysisRemoval = ({ map, mode, logout }) => {
  // Stateless Hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbarContext()

  // Redux State
  const showWarningForH3 = useSelector(state =>
    isAnalysisOnPartiallyDeletedMeasurementsForMode(state, Modes.H3))
  const showWarningForSegment = useSelector(state =>
    isAnalysisOnPartiallyDeletedMeasurementsForMode(state, Modes.Segment))

  // Local State
  const [isDeleting, setIsDeleting] = useState(false) // true while server processes deletion call

  /**
   * Updates map and the redux store with new segments
   *
   * @params segments Array of segments.
   */
  const updateSegments = (segments) => {
    dispatch(setSegments(segments.features))
    map.getSource(Sources.Segment).setData(normalize(segments))
    const forwardData = segments.features.filter(feature => feature.properties.forward_moving)
    const backwardData = segments.features.filter(feature => !feature.properties.forward_moving)

    map.getSource(Sources.ForwardArrows).setData(normalize({
      type: 'FeatureCollection',
      features: forwardData
    }))
    map.getSource(Sources.BackwardArrows).setData(normalize({
      type: 'FeatureCollection',
      features: backwardData
    }))
  }

  /**
   * Updates map and the redux store with new points
   *
   * @params h3 Array of h3s.
   */
  const updateH3 = (h3) => {
    dispatch(setH3(h3.features))
    map.getSource(Sources.H3).setData(h3)
    map.getSource(Sources.H3Overview).setData(overviewDataFromH3(h3))
  }

  /**
   * Handles the responses from the surface deletion API by adding the response jobs
   * and updating the surfaces.
   *
   * @params responses The responses from surface deletion API.
   */
  const handleResponse = async (response) => {
    if (response.status === Status.Finished) {
      dispatch(addJobs([response]))

      if (response.mode === Modes.H3) {
        const h3 = await getH3(dispatch, defaultErrorHandling, logout)
        updateH3(h3)
      } else if (response.mode === Modes.Segment) {
        const segments = await getSegments(dispatch, defaultErrorHandling, logout)
        // Unselect selected feature to avoid crash in SegmentDetails [DAT-1436]
        dispatch(updateInfrastructureView({ update: { clickedWayId: null }, map }))
        updateSegments(segments)
      }
    } else {
      enqueueSnackbar('Die Analyse konnte nicht gelöscht werden. Bitte kontaktieren Sie ' +
        supportEmail() + ' falls der Fehler wiederholt auftritt.')
    }
  }

  /**
   * Handler which is called when the user chooses to delete analysis based on deleted datasets.
   *
   * @param {*} e the corresponding event
   */
  const onClickDelete = async () => {
    const username = getLocalStorage(LocalStorage.Username)
    try {
      setIsDeleting(true)
      const response = mode === Modes.Segment
        ? await deleteSegments(dispatch, defaultErrorHandling, logout, username)
        : await deleteH3(dispatch, defaultErrorHandling, logout, username)
      handleResponse(response)
    } catch (error) {
      defaultErrorHandling(error, logout)
    } finally {
      setIsDeleting(false)
    }
  }

  const deprecated = mode === Modes.H3
    ? showWarningForH3
    : mode === Modes.Segment
      ? showWarningForSegment
      : new Error('Unsupported mode: ' + mode)

  return (
    <div>
      <IconWrapper>
      {deprecated
        ? (<WarningIcon style={{ color: 'gray' }} />)
        : (<DeleteIcon style={{ color: 'gray' }} />)}
      </IconWrapper>
      <Message>
        {isDeleting
          ? (
          <PreloaderContainer>
            <CircularProgress size={24} />
          </PreloaderContainer>
            )
          : (
          <div>
            <Info>
              {deprecated
                ? 'Analyse basiert auf gelöschten Messungen.'
                : 'Fehlerhaften Datensatz verarbeitet?'}
            </Info>
            <DeleteLink onClick={onClickDelete}>Analyse löschen</DeleteLink>
          </div>
            )}
      </Message>
      <div style={{ clear: 'both' }}></div>
    </div>
  )
}

AnalysisRemoval.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired
}

const IconWrapper = styled('div')({
  float: 'left',
  margin: '10px'
})

const Message = styled('div')({
  marginTop: '5px'
})

const Info = styled('div')({})

const PreloaderContainer = styled('div')({
  float: 'left',
  transform: 'scale(0.8)'
})

const DeleteLink = styled('a')({
  cursor: 'pointer',
  color: '#3F8730 !important'
})

export default AnalysisRemoval
