import React from 'react'
import playStoreLogo from '../../../resources/play-store.png'
import appStoreLogo from '../../../resources/app-store.png'

const AppDownloadLinks = () => (
  <center style={{ padding: '40px 0px 0px 0px' }}>
    Laden Sie unsere App herunter um Daten zu erfassen:
    <center style={{ margin: '30px 0px 0px 20px' }}>
      <a style={{ color: '#3F8730' }}
        href="https://play.google.com/store/apps/details?id=de.cyface.app&hl=de&gl=DE"
        target="_blank"
        rel="noreferrer">
        <img src={playStoreLogo} alt='Cyface im Google Play Store' width='250px' />
      </a><br /><br />
      <a style={{ color: '#3F8730' }}
        href="https://apps.apple.com/de/app/cyface/id1456291958?l=de"
        target="_blank"
        rel="noreferrer">
        <img src={appStoreLogo} alt='Cyface im Apple App Store' width='250px' />
      </a>
    </center>
  </center>
)

export default AppDownloadLinks
