import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../resources/logo_small.png'
import { AppBar, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

/**
 * The top bar of the dashboard.
 */
const Header = ({ right, mobileView }) => {
  return (
    <AppBar position="static" sx={
      {
        height: '70px',
        backgroundColor: 'transparent',
        borderBottom: '1px solid lightgrey',
        color: 'black'
      }}>
      <Toolbar sx={{
        alignItems: 'center',
        height: '70px',
        '& img': {
          height: '47px',
          marginRight: '10px',
          marginLeft: mobileView ? '71px' : '-4px'
        }
      }}>
        {/* Attention: The Menu-Button for the sidebar is part of the Sidebar! */ }

        {/* left */}
        <Logo alt='Cyface Logo' src={logo} $mobileView={mobileView} />

        {/* flexGrow 1 ensures the Typografie-Container uses the remaining space */}
        <Typography
          variant="h5"
          color='inherit'
          sx={{
            flexGrow: 1,
            fontSize: '25px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            marginTop: '2px'
          }}
        >
          Cyface
        </Typography>

        {/* right */}
        {right}
      </Toolbar>
    </AppBar>
  )
}

const Logo = styled('img')(({ $mobileView }) => ({
  float: 'left',
  marginRight: '10px',
  marginLeft: $mobileView ? '71px' : '0px',
  width: '47px',
  height: '47px'
}))

Header.propTypes = {
  right: PropTypes.object.isRequired,
  mobileView: PropTypes.bool.isRequired
}

export default Header
