import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ExpandLess, HelpOutline } from '@mui/icons-material'

/**
 * Text which can be shown at the bottom of the sidebar for a quick information about
 * the currently shown view.
 *
 * @author Armin Schnabel
 */
const InfoText = ({ text }) => {
  const icon = useMemo(() => <ExpandLess sx={{ color: 'rgb(170, 170, 170)' }} />, [])

  return (
    <Container>
      <Accordion>
        <AccordionSummary
          sx={{
            background: 'white',
            border: '1px solid rgb(221, 221, 221)'
          }}
          expandIcon={icon}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <HelpOutline sx={{ color: 'rgb(170,170,170)', fontSize: '20px', marginTop: '1px' }} />
          <Typography sx={{
            color: 'rgb(128, 128, 128)',
            fontSize: '15px',
            marginLeft: '10px'
          }}>
            Hilfe zu dieser Ansicht
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Content>
            {text}
          </Content>
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}

InfoText.propTypes = {
  text: PropTypes.string.isRequired
}

const SIDEBAR_ZINDEX = 1000
const Container = styled('div')({
  width: '400px',
  maxWidth: '80%',
  position: 'fixed',
  bottom: '0px',
  zIndex: SIDEBAR_ZINDEX + 1,
  backgroundColor: 'white',
  border: '1px solid rgb(221, 221, 221)'
})

const Content = styled('div')({
  padding: '10px 10px 10px 10px',
  color: 'rgb(128, 128, 128)',
  fontSize: '15px'

})

export default InfoText
