import React from 'react'
import PropTypes from 'prop-types'
import GenericIcon from '../GenericIcon'
import { isStatusOngoing, Status, isStatusUnsuccessful, isStatusSuccessful } from '../../JobHelpers'
import { Pipelines, Modes } from '../../sidebar/datasets/DatasetsAnalysis'
import JobsProgress from './JobsProgress'
import { supportEmail } from '../../login/utils'
import { useSelector } from 'react-redux'

/**
 * The jobs of a dataset shown as icons inside a dataset entry in the sidebar.
 *
 * @author Armin Schnabel
 */
const Jobs = ({ selected, checkable, jobs }) => {
  // Redux State
  const latestH3Deletion = useSelector(state => state.jobs.latestH3DeletionDate)
  const latestSegmentDeletion = useSelector(state => state.jobs.latestSegmentDeletionDate)

  /**
   * Returns a list of `GenericIcon`s.
   *
   * @param {*} jobs The jobs to return.
   * @returns The `GenericIcon`s.
   */
  const list = (jobs) => {
    // Return one component per job
    return jobs.map(job => {
      // We inject segment and history to calculate progress based on both
      // But we only want to show one icon and progress bar:
      if (job.mode === Modes.SegmentHistory) {
        return ''
      }
      return icon(job)
    })
  }

  /**
   * Generates an icon which represents a `job`.
   *
   * @param {*} job The job to visualize as icon.
   * @returns The `GenericIcon`.
   */
  const icon = (job) => {
    const tooltip = tooltipText(job)
    const status = decideStatus(job)

    // We currently don't expect jobs to be in this state, so we don't show anything
    if (status === Status.Canceled || status === Status.Suspended) {
      return
    }
    // Show job progress for ongoing jobs
    if (isStatusOngoing(status)) {
      return <JobsProgress
        key={job.id}
        mode={job.mode}
        tooltip={tooltip}
        jobs={job.mode === Modes.Segment ? jobs : [job]}
        status={status}
        selected={selected}
        checkable={checkable}
      />
    }

    // Ignore jobs created before result data deletion
    const deprecatedH3Job = job.pipeline === Pipelines.Surface && job.mode === Modes.H3 &&
      new Date(job.created) <= latestH3Deletion
    const deprecatedSegmentJob = job.pipeline === Pipelines.Surface && job.mode === Modes.Segment &&
      new Date(job.created) <= latestSegmentDeletion
    if (deprecatedH3Job || deprecatedSegmentJob) {
      return
    }

    // Return icon for jobs in final state (e.g. unprocessable or finished)
    const iconStatusText = statusText(status)
    const iconColor = color(status)
    const icon = finalIcon(job)
    return <GenericIcon
      key={job.id}
      margin='8px 0px 0px 0px'
      tooltip={tooltip + iconStatusText}
      float="right"
      color={iconColor}
      icon={icon}
    />
  }

  /**
   * Returns status string depending on the job pipeline mode and status.
   *
   * In case of a segment job, both segment and history job is considered.
   *
   * @param {*} job The job to decide the status for.
   * @returns The `Status`.
   */
  const decideStatus = (job) => {
    const locationInfo = job.locations !== undefined && job.locations !== 'null'
    const rotated = locationInfo && job.locations === job.rotatedLocations
    const filteredLocations = parseInt(job.rotatedLocations, 10) +
      parseInt(job.invalidLocations, 10) + parseInt(job.nonInterpolatableLocations, 10)
    const filtered = locationInfo && parseInt(job.locations, 10) === filteredLocations

    // Non-segment job does not require special logic.
    if (job.mode !== Modes.Segment) {
      return internalStatus(rotated, filtered, job.status)
    }

    // Return unsuccessful status if any segment related job is unsuccessful
    const unsuccessfulJob = jobs.find(j => j && isStatusUnsuccessful(j.status))
    if (unsuccessfulJob !== undefined) {
      return internalStatus(rotated, filtered, unsuccessfulJob.status)
    }

    // Return created status if a sub-job is not yet running
    const nonStartedJobExist = jobs.some(j => j && j.status === Status.Created)
    if (nonStartedJobExist) {
      return Status.Created
    }

    // Return finished status when both jobs are finished
    if (jobs.every(j => j && isStatusSuccessful(j.status))) {
      return Status.Finished
    }

    // Return running status for any other combination (containing a running sub-job)
    return Status.Running
  }

  /**
   * Applies the status types only used in the web-app to the types shared with the API.
   *
   * @param {*} rotated `true` if all locations were unprocessible because of calibration issues.
   * @param {*} filtered `true` if all locations were filtered due to some filter.
   * @param {*} status The status received from the API.
   */
  const internalStatus = (rotated, filtered, status) => {
    return rotated ? Status.Rotated : filtered ? Status.Filtered : status
  }

  /**
   * Returns color string depending on the input status.
   *
   * @param {*} status The status to generate the color.
   * @returns Color string.
   */
  const color = (status) => {
    const failed = status === Status.Failed
    const unknown = status === Status.Unknown
    const unprocessable = status === Status.Unprocessable
    const rotated = status === Status.Rotated
    const filtered = status === Status.Filtered
    const errorColor = '#ff1414'
    const warningColor = '#ffad17'
    const infoColor = '#76ddff'
    const defaultColor = '#c1c1c1'
    return failed
      ? errorColor
      : (unknown || unprocessable)
          ? warningColor
          : (rotated || filtered)
              ? infoColor
              : defaultColor
  }

  /**
   * Returns status text depending on the input status.
   *
   * @param {*} status The status to generate the text for.
   * @returns The generated text.
   */
  const statusText = (status) => {
    const failed = status === Status.Failed
    const unknown = status === Status.Unknown
    const unprocessable = status === Status.Unprocessable
    const rotated = status === Status.Rotated
    const filtered = status === Status.Filtered
    return failed
      ? ' - Verarbeitung Fehlgeschlagen'
      : unprocessable
        ? ' - Verarbeitung nicht möglich. Bitte kontaktieren Sie ' + supportEmail()
        : rotated
          ? ' - Verarbeitung nicht möglich, Messung verwackelt.'
          : filtered
            ? ' - Verarbeitung nicht möglich, Positionsdaten nicht verarbeitbar.'
            : unknown
              ? ' - Verarbeitungsstatus unbekannt. Bitte versuchen Sie es erneut oder' +
          ' kontaktieren Sie ' + supportEmail()
              : ' - Verarbeitet' // finished job
  }

  /**
   * Returns tooltip text depending on the jobs mode and pipeline.
   *
   * @param {*} job The considered job.
   * @returns tooltip text string.
   */
  const tooltipText = (job) => {
    return job.pipeline === Pipelines.Surface && job.mode === Modes.H3
      ? 'Oberflächenanalyse (Heatmap)'
      : job.pipeline === Pipelines.Surface && job.mode === Modes.Segment
        ? 'Oberflächenanalyse (Segmente)'
        : job.pipeline === Pipelines.MapMatching
          ? 'Map-Matching '
          : 'Unkannte Pipeline'
  }

  /**
   * Returns the icon which represents a job in a final state.
   *
   * @param {*} job The considered job.
   * @returns A GenericIcon.
   */
  const finalIcon = (job) => {
    switch (job.pipeline) {
      case Pipelines.Surface:
        switch (job.mode) {
          case Modes.H3: return 'blur_on' // "apps", "grid_4x4" - all not really good
          case Modes.Segment: return 'reorder' // "dashboard", "view_week" - all not really good
          default: throw Error('Unexpected surface mode: ' + job.mode)
        }

      case Pipelines.MapMatching: return 'linear_scale'

      default: throw Error('Unexpected pipeline: ' + job.pipeline)
    }
  }

  return (
    list(jobs)
  )
}

Jobs.propTypes = {
  selected: PropTypes.bool.isRequired,
  checkable: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired
}

export default Jobs
