import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ControlButton from '../ControlButton'
import CustomCheckbox from '../CustomCheckbox'
import { Typography, Box, List, ListItemText, ListItem, ListItemIcon, Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'

/**
 * Dialog which display the terms and allows the user to accept or decline them.
 *
 * It also contains the opt-in for error tracking.
 *
 * @author Armin Schnabel
 */
const TermsOfUse = ({ handleTerms }) => {
  // Stateless Hooks
  const theme = useTheme()

  // Local state
  const [errorTrackingEnabled, setErrorTrackingEnabled] = useState(false)

  /**
   * Handler which is called when an this checkbox is changed.
   */
  const onErrorTrackingChange = (event) => {
    setErrorTrackingEnabled(event.target.checked)
  }

  return (
    <Box
      sx={{
        ...theme.typography.body1,
        position: 'fixed',
        top: 'calc((100dvh - min(80dvh, 800px)) / 2)',
        left: 'calc((100vw - min(80vw, 700px)) / 2)',
        height: '80dvh', // See [RFR-1086]: vh behaves differently on mobile phones
        maxHeight: '800px',
        width: '80vw',
        maxWidth: '700px',
        overflow: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
        border: 'solid #ccc',
        boxShadow: '0 0 5px 2px rgba(0,0,0,.1)',
        borderWidth: 'thin',
        borderRadius: '10px',
        color: 'rgb(102, 102, 102)'
      }}
    >
      <Typography variant="h5" align='center' sx={{ mt: 4, mb: 2 }}>
        Mapbox
      </Typography>
      {/* Use Box instead of Typography for the paragraph to avoid <p> within <p> */}
      <Box sx={{ mb: 2 }}>
        Wir benutzen auf unserer Seite Funktionen des Kartendienstes Mapbox.
        Angeboten werden diese Funktionen von der Firma Mapbox Inc. Bei der Nutzung
        von Mapbox Diensten wird eine Verbindung zu den Servern von Mapbox aufgebaut.
        Hierbei wird Ihre IP Adresse an Mapbox übertragen, gespeichert und
        verarbeitet. Diese Seite hat kein Einfluss auf die Übertragung dieser Daten an
        Mapbox.<br /><br />
        Weitere Informationen finden Sie in der&nbsp;
        <Link href='https://www.mapbox.com/privacy/' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung von Mapbox</Link>.
      </Box>

      <Typography variant="h5" align='center' sx={{ mt: 4, mb: 2 }}>
        Cookies
      </Typography>
      {/* Use Box instead of Typography for the paragraph to avoid <p> within <p> */}
      <Box sx={{ mb: 2 }}>
        Um sicher mit dem Radsim Server zu kommunizieren, speichern wir
        Einstellungen und Zugangscodes im lokalen Speicher Ihres Browsers.
      </Box>

      <Typography variant="h5" align='center' sx={{ mt: 4, mb: 2 }}>
        Fehler-Tracking durch Sentry
      </Typography>
      { /* No scroll view used here as on iOS `overflow:auto` doesn't show scrollbars. Some users
          might not know that they have to scroll the "outer" scroll view [RFR-1087] */}
      {/* Use Box instead of Typography for the paragraph to avoid <p> within <p> */}
      <Box sx={{ mb: 2 }}>
        Wir verbessern und entwickeln unsere App ständig weiter. Trotzdem können
        wir keinenfehlerfreien Betrieb der App garantieren. Um mögliche
        Programmierfehler so gering wie möglich zu halten, verwenden wir Sentry,
        ein Service von Functional Software Inc., 132 Hawthorne St, San Francisco,
        CA 94107, USA (nachfolgend &quot;Sentry&quot;).
        Sentry überwacht Fehler die innerhalb unserer App auftreten und informiert
        uns über diese. Im Falle eines Software-Fehlers werden automatisch
        folgende Informationen an Sentry übermittelt:
        <List dense>
          {
            [
              'Fehlermeldungen, die bei der Verwendung der App auftreten',
              // eslint-disable-next-line max-len
              'Informationen über die Verwendung der App unmittelbar vor dem Auftreten des Fehlers',
              'Datum und Uhrzeit der Fehler, Startzeitpunkt der App',
              'Geräteinformationen: Betriebssystem, App Version und Gerätemodell',
              'Kennung zur Identifizierung von Fehlern desselben Geräts',
              // eslint-disable-next-line max-len
              'Status des Geräts: Batterieladung und -temperatur, Ladezustand, Zeitpunkt des Gerätestarts, Arbeitsspeicher, Verbindungstyp (Wi-Fi/Mobil-Daten), Speicherplatz, verfügbarer Speicher, bevorzugte Sprache, Geräteausrichtung, Bildschirmauflösung und -dichte, Zeitzone',
              'IP-Adresse des Geräts (durch den Verbindungsaufbau)'
            ].map((item, index) => (
              <ListItem key={index} sx={
                { alignItems: 'flex-start', padding: '0px 0px 0px 10px' }}>
                <ListItemIcon sx={{ minWidth: '5%', paddingTop: '5px' }}>
                  {/* <p> not allowed inside <p> (ListItemText), so we use <span> */}
                  <Typography component="span">•</Typography>
                </ListItemIcon>
                <ListItemText>
                  { /* <div>(Typography) not allowed inside <p> (ListItemText) */}
                  <Box component="span" sx={{ display: 'inline', color: 'rgb(102, 102, 102)' }}>
                    {item}
                  </Box>
                </ListItemText>
              </ListItem>
            ))
          }
        </List>
        Die Informationen werden anonym erhoben, nicht für persönliche Zwecke oder zu
        Werbezwecken verwendet und nach einem Zeitraum von 90 Tagen gelöscht. Rechtsgrundlage
        für die vorgenannte Datenverarbeitung ist Art. 6, Abs. 1 lit. f der DSGVO. Zu diesem
        Zweck wurde mit der Functional Software Inc. ein Auftragsverarbeitungsvertrag
        (AV-Vertrag) abgeschlossen.
        <br /><br />
        Zweck der Verarbeitung ist ausschließlich die Verbesserung der Stabilität und
        Nutzbarkeit der App. Weitere Informationen bzgl. der Datenverarbeitung durch Sentry
        finden Sie in den <Link href="https://sentry.io/privacy/">Datenschutzrichtlinien von Sentry</Link>.
      </Box>

<Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
  <CustomCheckbox
    checked={errorTrackingEnabled}
    onChange={onErrorTrackingChange}
    label="Fehlermeldungen und App-Nutzung automatisch übermitteln"
    />
</Box>

<Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
  <ControlButton
    text="Akzeptieren"
    onClick={() => { handleTerms(true, errorTrackingEnabled) }} />
  <ControlButton
    text="Ablehnen"
    onClick={() => { handleTerms(false, errorTrackingEnabled) }} />
</Box>
</Box>
  )
}

TermsOfUse.propTypes = {
  handleTerms: PropTypes.func.isRequired
}

export default TermsOfUse
