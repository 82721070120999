import React from 'react'
import GenericIcon from './GenericIcon'

/**
 * This file contains modality based constants and utility functions.
 */

/**
 * The possible modality filters which can be selected by the user.
 */
export const Modalities = {
  All: 'ALL',
  Car: 'CAR',
  Bicycle: 'BICYCLE',
  Motorbike: 'MOTORBIKE',
  Bus: 'BUS',
  Train: 'TRAIN',
  Walking: 'WALKING'
}

/**
 * Translates a modality filter option to German.
 *
 * @param {Modalities} modality The modality to translate
 * @returns The translated modality.
 */
export const modalityToGerman = (modality) => {
  switch (modality) {
    case Modalities.All:
      return 'Alle'
    case Modalities.Car:
      return 'Auto'
    case Modalities.Bicycle:
      return 'Fahrrad'
    case Modalities.Motorbike:
      return 'Motorrad'
    case Modalities.Bus:
      return 'Bus'
    case Modalities.Train:
      return 'Zug'
    case Modalities.Walking:
      return 'Zu Fuß'
    default:
      throw new Error('Unknown modality: ' + modality)
  }
}

const icon = (left, defaultLeft, margin, transform, tooltip, icon, color, float = 'left') => {
  return <GenericIcon
    position="relative"
    left= {left !== undefined ? left : defaultLeft}
    margin={margin !== undefined ? margin : '0px 0px 0px -11px'}
    tooltip={tooltip}
    icon={icon}
    float={float}
    transform={transform !== undefined ? transform : 'scale(1.0)'}
    color={color}/>
}

const modalityIcon = (modality, transform, left, margin, color) => {
  switch (modality) {
    case Modalities.Bicycle:
      return icon(left, '265px', margin, transform, modalityToGerman(modality),
        'directions_bike', color)
    case Modalities.Car:
      return icon(left, '280px', margin, transform, modalityToGerman(modality),
        'directions_car', color)
    case Modalities.Motorbike:
      return icon(left, '250px', margin, transform, modalityToGerman(modality),
        'motorcycle', color)
    case Modalities.Bus:
      return icon(left, '300px', margin, transform, modalityToGerman(modality),
        'directions_bus', color)
    case Modalities.Train:
      return icon(left, '298px', margin, transform, modalityToGerman(modality),
        'directions_subway', color)
    case Modalities.Walking:
      return icon(left, '275px', margin, transform, modalityToGerman(modality),
        'directions_walk', color)
    default:
      throw new Error('Unknown modality: ' + modality)
  }
}

export default modalityIcon
