import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Chip, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { removeActiveTag } from '../../../reducers/datasetsView'

const TagFilter = ({ activeTags }) => {
  // Stateless hooks
  const dispatch = useDispatch()

  if (activeTags.length === 0) {
    return null
  }

  return (
    <>
      <label>Tag-Filter</label>
      <TagContainer>
        {activeTags.map(tag => {
          const key = Object.keys(tag)[0]
          const value = Object.values(tag)[0]
          return (
            <StyledChip
              key={key + value}
              label={`${key}: ${value}`}
              onDelete={() => dispatch(removeActiveTag(tag))}
              deleteIcon={<IconButton><CloseIcon /></IconButton>}
            />
          )
        })}
      </TagContainer>
    </>
  )
}

TagFilter.propTypes = {
  activeTags: PropTypes.array.isRequired
}

const TagContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px'
})

const StyledChip = styled(Chip)({
  fontSize: '14px',
  backgroundColor: '#f1f1f1'
})

export default TagFilter
