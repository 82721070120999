import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import Datasets from './Datasets'
import DatasetsFilter from './DatasetsFilter'
import DatasetsAnalysis from './DatasetsAnalysis'
import DatasetsRemoval from './DatasetsRemoval'
import { Views } from '../../constants/Views'
// TODO [RFR-301]: import MeasurementTagging from './MeasurementTagging'
// TODO [RFR-301]: import ModifyTags from './ModifyTags.js'
import { States } from '../../../reducers/datasetsView'
import TagFilter from './TagFilter'

/**
 * Control elements shown in the 'dataset' mode where the user can view and filter the
 * sdata analysis results.
 *
 * @author Armin Schnabel
 */
const DatasetsView = ({ map, logout, viewControls }) => {
  // Redux state
  const datasetsView = useSelector(state => state.datasetsView) // State of this component
  const view = useSelector(state => state.ui.view) // Changes when e.g. switching to "AnalysisView"

  const shouldRenderDatasetList = useCallback(() => {
    const active = datasetsView.active
    return active !== States.AddRemoveAvailableTags &&
           active !== States.RemoveAvailableTags &&
           active !== States.AddAvailableTags
  }, [datasetsView.active])

  return (
    <Container $view={view}>
      <Actions>
        {viewControls}

        <label>Filter</label>
        <DatasetsFilter
          modality={datasetsView.modality}
          time={datasetsView.time}
          tags={datasetsView.tags}
          disabled={!shouldRenderDatasetList()}
        />

        <TagFilter activeTags={datasetsView.tags.activeTags} />

        { /* Simple actions */ }
        <label>Aktionen</label>
        <DatasetsRemoval
          logout={logout}
          active={datasetsView.active}
          selected={datasetsView.selected}
        />
        {/* TODO [RFR-301]: <MeasurementTagging
          active={datasetsView.active}
          availableTags={datasetsView.tags.availableTags}
          setActive={(active) => dispatch(updateDatasetsView({ active }))}/>
        <ModifyTags
          active={datasetsView.active}
          tags={datasetsView.tags}
          setActive={(active) => dispatch(updateDatasetsView({ active }))}
          logout={logout} /> */}

        { /* Actions with mode selection */ }
        <DatasetsAnalysis
          logout={logout}
          active={datasetsView.active}
          selected={datasetsView.selected}
        />
      </Actions>

    { shouldRenderDatasetList()
      ? <Scrollable><Datasets map={map} logout={logout} /></Scrollable>
      : '' }

      { /* <AddDataset map={map} /> */ }
    </Container>
  )
}

DatasetsView.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  viewControls: PropTypes.node.isRequired
}

// const COLLAPSIBLE_ITEM_HEIGHT_PIXEL = 79 somehow that's not enough
const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$view'
})(({ $view }) => ({
  display: $view === Views.Datasets ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'stretch',
  position: 'static', // not relative
  height: '100%'
}))

const Actions = styled(Box)({
  padding: '0px 10px 10px 10px'
})

const Scrollable = styled(Box)({
  padding: '0px 10px 10px 10px',
  overflowY: 'auto'
})

export default DatasetsView
