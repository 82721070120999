import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, ListItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Colors } from '../constants/Colors'

/**
 * This component represents an item in a list of collection. Designed to have different
 * behaviour in dataset analysis mode.
 *
 * @author Armin Schnabel
 */
const CustomCollectionItem = ({
  active, selected, selectable, onClick, item, text, checkable,
  addSelected, removeSelected, addTagsToMeasurements, disabled = false
}) => {
  /**
   * Handler which is called when an this collection item was marked or unmarked.
   *
   * @param {*} e The click event.
   */
  const onChangeCheckBox = (e, prevChecked) => {
    e.preventDefault()
    if (selectable(item)) {
      if (prevChecked) {
        removeSelected(item.id)
      } else {
        addSelected(item.id)
      }
    }
  }

  const onDrop = async (e) => {
    e.preventDefault()
    const tag = JSON.parse(e.dataTransfer.getData('text'))
    await addTagsToMeasurements(item, tag)
  }

  const onDragIgnore = (e) => {
    e.preventDefault()
  }

  const checked = checkable && selected
  return (
    <StyledListItem
      sx={{
        backgroundColor: active ? Colors.Primary : 'white',
        color: active ? 'white' : 'rgb(70, 70, 70)',
        cursor: disabled ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor: !disabled && !active ? '#f5f5f5' : undefined
        }
      }}
      selected={active}
      onClick={checkable
        ? (e) => onChangeCheckBox(e, checked)
        : disabled
          ? () => {}
          : (e) => onClick(e, item)
      }
      onDragOver={onDragIgnore}
      onDrop={onDrop}
      onDragEnter={onDragIgnore}
      onDragLeave={onDragIgnore}
    >
      {checkable && (
        <Checkbox
          checked={checked}
          onChange={(e) => onChangeCheckBox(e, checked)}
          sx={{
            transform: 'scale(0.8)',
            mt: '-5px',
            mb: '-5px'
          }}
        />
      )}
      {text}
    </StyledListItem>
  )
}

CustomCollectionItem.propTypes = {
  active: PropTypes.bool.isRequired,
  checkable: PropTypes.bool.isRequired,
  selectable: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.object,
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  addSelected: PropTypes.func.isRequired,
  removeSelected: PropTypes.func.isRequired,
  addTagsToMeasurements: PropTypes.func.isRequired
}

const StyledListItem = styled(ListItem)(() => ({
  display: 'flex',
  draggable: 'false'
}))

export default CustomCollectionItem
