import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateUi } from '../../reducers/ui'
import InfoText from './InfoText'
import { Views } from '../constants/Views'
import ControlButton from '../ControlButton'

/**
 * Sidebar for the map which contains control elements and the segment detail view.
 *
 * @author Armin Schnabel
 */
const Sidebar = ({ children, mobileView }) => {
  // Stateless Hooks
  const dispatch = useDispatch()

  // Redux State
  const view = useSelector(state => state.ui.view) // Changes when e.g. switching to "AnalysisView"
  const sidebar = useSelector(state => state.ui.sidebar) // Only changes when sidebar hides/expands

  return (
    <div>
      { /* Sidebar-Trigger for mobile view */ }
      <Trigger
        $display={mobileView ? 'block' : 'none'}>
        <ControlButton
          icon='menu'
          margin=''
          tooltip="Seitenleiste ein-/ausblenden"
          onClick={() => dispatch(updateUi({ sidebar: !sidebar }))} />
      </Trigger>

      <Container
        $display={mobileView && !sidebar ? 'none' : 'flex'}
        $background={mobileView && !sidebar ? 'transparent' : 'white'}
        $mobileView={mobileView}>

        {children}

        <InfoText
          text={view === Views.Datasets
            ? "Im Bereich 'Datensätze' werden die von Ihnen hochgeladenen Messungen angezeigt." +
            ' Neue Messungen können Sie über unserer Android und iPhone App aufzeichnen.'
            : view === Views.Infrastructure
              ? "Im Bereich 'Analysen/Infrastruktur' können Sie Analyseergebnisse, wie z.B. die" +
          ' Oberflächenbeschaffenheit, einsehen. Wählen Sie auf der Karte ein farblich' +
          ' hinterlegtes Straßensegment aus um Details einzusehen. Wechseln Sie in den Bereich' +
          " 'Datensätze' um zu sehen welche Messungen in die Analyse einbezogen wurden."
              : "Im Bereich 'Export' können Sie sich die Geo-Tracks Ihrer Fahrten und " +
              'die analysierten Straßensegmente herunterladen' } />

        <Overlay
          $display={mobileView && sidebar ? 'block' : 'none'}
          $opacity={mobileView && sidebar ? '1' : '0'}
          onClick={() => dispatch(updateUi({ sidebar: !sidebar }))} />

      </Container>
    </div>)
}

Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  mobileView: PropTypes.bool.isRequired
}

const Trigger = styled('div')(({ $display }) => ({
  display: $display,
  position: 'fixed', // relative to the viewport
  top: '18px',
  left: '20px'
}))

const Container = styled('div')(({ $display, $mobileView, $background }) => ({
  display: $display,
  flexDirection: 'column',
  position: 'fixed', // relative to the viewport
  top: '70px',
  zIndex: $mobileView ? '999' : 'auto', // refer to RFR-665
  height: 'calc(100vh - 70px - 48px)',
  width: '400px',
  maxWidth: '80%',
  background: $background,
  fontFamily: "'Helvetica', 'Arial', sans-serif",
  fontSize: '9pt',
  lineHeight: 2,
  color: '#9e9e9e',
  borderRight: 'solid',
  borderRightWidth: '1px',
  borderColor: 'lightgrey',
  '& a': {
    color: 'white'
  },
  '& a:hover, & a:active, & a:focus': {
    color: 'white'
  }
}))

const Overlay = styled('div')(({ $display, $opacity }) => ({
  display: $display,
  opacity: $opacity,
  transition: 'visibility 0s, opacity 0.5s linear',
  position: 'fixed', // relative to the viewport
  top: '70px',
  right: '0px',
  zIndex: 997,
  marginTop: 0,
  height: 'calc(100vh - 70px)',
  width: 'calc(100% - 400px)',
  minWidth: 'calc(100% - 80%)',
  background: 'rgba(0,0,0,0.5)'
}))

export default Sidebar
