import React, { useRef, useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { OVERVIEW_ZOOM_LEVEL, addDatasetsLayer, addExportLayer, addPointLayer, addSegmentLayer } from '../map/MapBoxHelpers.js'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { asyncRefreshTokenIfRequired, getLocalStorage, getWebsocketApiUrl, loggedIn } from '../login/utils.js'
import { getH3, getMeasurements, getSegments, reloadProcessedDataIfNecessary } from '../DataApi.js'
import { useSnackbarContext } from '../SnackbarContext.js'
import { defaultErrorHandling, defaultWebsocketErrorHandling } from '../ErrorHandlingHelpers.js'
import { Box, LinearProgress } from '@mui/material'
import useMapFeatureHandlers from '../map/useMapFeatureHandler.js'
import { Styles } from '../constants/Styles.js'
import { setVisibleLayerId, updateUi } from '../../reducers/ui.js'
import { Views } from '../constants/Views.js'
import { Sources } from '../constants/Sources.js'
import { Directions } from '../constants/Directions.js'
import { Modalities } from '../sidebar/Modality.js'
import { addJobsToMeasurements, setMeasurements } from '../../reducers/measurements.js'
import { setH3 } from '../../reducers/h3.js'
import { setSegments } from '../../reducers/segments.js'
import { areTagsEqual, tagSortCompare } from '../TagHelpers.js'
import { setAvailableTags } from '../../reducers/datasetsView.js'
import { LocalStorage } from '../constants/LocalStorage.js'
import { Endpoints } from '../constants/Endpoints.js'
import { addJobs } from '../../reducers/jobs.js'
import { timeout } from '../JobHelpers.js'
import Legend from '../sidebar/infrastructure/Legend.js'
import useSocketManager from '../../hooks/useSocketManager'
import DatasetPreloader from './DatasetPreloader.js'

/**
 * The initial zoom of the map which is a level where whole Germany is shown on mobile view.
 */
const initialZoom = 5

const MapContainer = ({
  map,
  setMap,
  logout,
  mobileView,
  accessToken,
  defaultLocation,
  sidebarLess,
  style,
  width
}) => {
  // Stateless Hooks
  const dispatch = useDispatch()
  const { addSocket, removeSocket } = useSocketManager() // stateless unless you extract `sockets`
  const { enqueueSnackbar } = useSnackbarContext()

  // The document to render the Mapbox map in
  const mapContainer = useRef(null)

  // Local state
  const [zoomLevel, setZoomLevel] = useState(initialZoom) // only changes passing overview threshold
  const zoomRef = useRef()
  zoomRef.current = zoomLevel
  const [h3Loaded, setH3Loaded] = useState(false) // Only changes once when dataset is loaded
  const [segmentsLoaded, setSegmentsLoaded] = useState(false) // Only changes once

  const { setMapMouseHandlers } = useMapFeatureHandlers(map)

  /**
   * Effects which depend on no state/prop (i.e. only executed on un-/mount).
   *
   * The first part is called when the component is inserted into the DOM.
   * The returned function is called when the component is removed from the DOM.
   *
   * Sets up the mapbox-map including the onClick etc. handlers.
   */
  useEffect(() => {
    if (map) return // initialize map only once

    // Required as it's not allowed to useEffect(async ()...
    const initializeMap = async () => {
      // Load data from backend
      const data = await loadData()

      // Setup map if not already initialized
      mapboxgl.accessToken = accessToken
      if (!map && mapContainer.current) {
        const newMap = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/light-v11?optimize=true',
          center: defaultLocation,
          zoom: initialZoom // At this moment always the default value
        })

        // Add zoom control
        const navControl = new mapboxgl.NavigationControl()
        newMap.addControl(navControl, 'top-left')

        // Add search bar
        newMap.addControl(
          new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            language: 'de-DE',
            mapboxgl
          })
        )

        // Add Scale
        const mapScale = new mapboxgl.ScaleControl({
          maxWidth: 120,
          unit: 'metric'
        })
        newMap.addControl(mapScale)

        // Set ZoomSpeed
        newMap.scrollZoom.setWheelZoomRate(1 / 100)

        newMap.on('zoomend', async () => {
          // Only update when zoom level crosses the overview zoom level
          const newZoom = newMap.getZoom()
          if ((zoomRef.current < OVERVIEW_ZOOM_LEVEL && newZoom >= OVERVIEW_ZOOM_LEVEL) ||
              (zoomRef.current > OVERVIEW_ZOOM_LEVEL && newZoom <= OVERVIEW_ZOOM_LEVEL)) {
            setZoomLevel(newZoom)
          }
        })

        // Register handler
        setMapMouseHandlers(newMap, dispatch)

        // Set map state when loaded + set state data
        newMap.on('load', async (e) => {
          const { h3, segments, measurements } = data
          addDatasetsLayer(newMap, [])
          addPointLayer(newMap, h3)
          addSegmentLayer(newMap, segments.features)
          addExportLayer(newMap, [])

          // Change default view to H3 - for RFR UI
          if (sidebarLess) {
            dispatch(updateUi({ view: Views.Infrastructure }))
            dispatch(setVisibleLayerId(newMap, Sources.H3, Directions.Unknown, Modalities.All))
          }

          // Set the map after everything else is set up
          setMap(newMap)
          watchJobs(newMap)

          // Add to Redux store (see [RFR-610])
          dispatch(setMeasurements(measurements))
          dispatch(setH3(h3.features))
          dispatch(setSegments(segments.features))
        })
      }
    }

    if (mapContainer.current) initializeMap({ setMap, mapContainer })
    // eslint-disable-next-line
  }, [/* map */]) // effect depends on no state/props: only run on un-/mount, not re-render

  const loadData = async () => {
    const [measurements, h3, segments] = await Promise.all([
      getMeasurements(dispatch, defaultErrorHandling, logout),
      getH3(dispatch, defaultErrorHandling, logout, setH3Loaded),
      getSegments(dispatch, defaultErrorHandling, logout, setSegmentsLoaded)])

    const availableTags = measurements.reduce((accumulator, measurement) => {
      if (measurement.tags !== undefined) {
        Object.entries(measurement.tags).forEach(tag => {
          const tagObj = Object.fromEntries([tag])
          if (accumulator.find(savedTag => areTagsEqual(tagObj, savedTag)) === undefined) {
            accumulator.push(tagObj)
          }
        })
      }
      return accumulator
    }, []).sort(tagSortCompare)

    // initialize availabletags from measurements.
    dispatch(setAvailableTags(availableTags))

    return {
      measurements,
      h3,
      segments
    }
  }

  /**
   * Subscribe to the current jobs state and future inserts and updates.
   *
   * In JS, we cannot add headers to the Websocket request.
   * We don't want to send it through the url parameters. (https://stackoverflow.com/a/26123316/5815054)
   * We send it through the sub-protocols header as commonly done.
   * Sending it as first message is possible, too, but needs more code to be maintained.
   *
   * The sub-protocols may not contain spaces which is why we send ['Bearer', 'token] instead
   * of ['sub-protocol', 'Bearer eyToken..]". The static sub-protocol 'Bearer' must be accepted
   * in the server response of the Websocket handshake.
   *
   * @param map The map to update after a job finished.
   * @param socketHandler A handler which is called when a new socket is created.
   */
  const watchJobs = async (map) => {
    await asyncRefreshTokenIfRequired()
    const subProtocols = ['Bearer', getLocalStorage(LocalStorage.AccessToken)]
    const socket = new WebSocket(getWebsocketApiUrl() + Endpoints.Jobs, subProtocols)

    socket.onmessage = async (event) => {
      const response = JSON.parse(event.data)
      if (response.type === 'heartbeat') {
        return
      }

      // Update jobs
      const changedJobs = response.jobs
      dispatch(addJobs(changedJobs))

      // Link jobs to measurements
      changedJobs.forEach(job => {
        const measurementId = job.deviceId + ':' + job.measurementId
        dispatch(addJobsToMeasurements([measurementId], [job.id]))
      })

      reloadProcessedDataIfNecessary(response, map, logout, dispatch)
    }

    socket.onopen = async () => addSocket(socket)
    socket.onclose = async (event) => {
      removeSocket(socket)

      // Only reconnect if user is still logged in
      if (loggedIn()) {
        defaultWebsocketErrorHandling(event.code, logout, async () => {
          await timeout(5_000)
          enqueueSnackbar('Verbindung unterbrochen, wird wiederhergestellt ...')
          watchJobs(map)
        })
      }
    }
    socket.onError = async (event) => {
      defaultWebsocketErrorHandling(event.code, logout, async () => {
        await timeout(5_000)
        enqueueSnackbar('Verbindung fehlgeschlagen, wird wiederhergestellt ...')
        watchJobs(map)
      })
    }

    return socket
  }

  // Only changes once when dataset is initially loaded
  const progress = h3Loaded * 50 + segmentsLoaded * 50
  const showProgress = map === null || (map !== null && progress !== 100)

  // Style calculations
  const progressBarLeft = mobileView ? (width / 2) - 50 : (width + 400) / 2
  const progressBarTop = window.innerHeight / 2

  return (
    <div>
      <Mapbox
        ref={(el) => { mapContainer.current = el }}
        $headerHeight={style === Styles.RFR ? '100px' : '70px'}
        $sidebarSize={mobileView ? '0px' : '400px'}
      />

      { showProgress && (
        <ProgressBarContainer left={progressBarLeft} top={progressBarTop}>
          <LinearProgress variant="determinate" value={progress} />
        </ProgressBarContainer>
      )}

      <DatasetPreloader mobileView={mobileView} width={width} />

      <Legend mobileView={mobileView} zoomLevel={zoomLevel} />
    </div>
  )
}

MapContainer.propTypes = {
  map: PropTypes.object,
  setMap: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  accessToken: PropTypes.string.isRequired,
  defaultLocation: PropTypes.array.isRequired,
  sidebarLess: PropTypes.bool.isRequired,
  style: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
}

const Mapbox = styled('div')(({ $headerHeight, $sidebarSize }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  height: `calc(100% - ${$headerHeight})`,
  width: `calc(100% - ${$sidebarSize})`
}))

const ProgressBarContainer = styled(Box)(({ left, top }) => ({
  zIndex: 3,
  position: 'relative',
  left,
  top,
  width: '100px'
}))

export default MapContainer
