/**
 * Osm information.
 */

// Object lookup for surfaceType
const surfaceTypes = {
  paved: 'Versiegelte Oberfläche',
  asphalt: 'Asphalt',
  chipseal: 'dünne Bitumen- oder Asphaltschicht',
  concrete: 'Beton',
  'concrete:lanes': 'Betonspurbahnen',
  'concrete:plates': 'Betonplatten',
  paving_stones: 'Pflastersteinen',
  sett: 'behauenes Steinpflaster',
  unhewn_cobblestone: 'rohes Kopfsteinpflaster',
  cobblestone: 'Kopfsteinpflaster',
  'cobblestone:flattened': 'Steinpflaster mit abgeflachten Steinen',
  metal: 'Metall',
  wood: 'Holz',
  stepping_stones: 'Trittsteinen',

  unpaved: 'Ohne Straßenbelag',
  compacted: 'verdichtete Deckschicht aus Natursteinbruch',
  fine_gravel: 'fester Splitt',
  gravel: 'loser Schotter',
  rock: 'Natursteinweg',
  pebblestone: 'looser Kies',
  ground: 'gewachsener, naturbelassener Oberfläche',
  dirt: 'Unbefestigte Straße',
  earth: 'unbedeckte Erde',
  grass: 'Gras/Rasen/Wiese',
  grass_paver: 'Rasengittersteine',
  mud: 'Matsch/Morast/Schlamm/Schlick',
  sand: 'Loser Sand',
  woodchips: 'Hackschnitzel',
  snow: 'Schnee',
  ice: 'Eisstraße',
  salt: 'Salzseen',

  clay: 'Sandplatz für Sportplatz',
  tartan: 'Tartan- oder Kunststoffbelag',
  artificial_turf: 'Kunstrasen',
  acrylic: 'Acrylharzoberfläche',
  metal_grid: 'Metallgitter',
  carpet: 'Teppich'
}

// Object lookup for highwayType
const highwayTypes = {
  motorway: 'Autobahn',
  trunk: 'Kraftfahrstraße',
  primary: 'Bundesstraße',
  secondary: 'Landes-/Staatsstraße',
  tertiary: 'Kreisstraße oder Innerstädtische Vorfahrsstraße',
  unclassified: 'Erschließungsstraße/kleinere Kreisstraße',
  residential: 'Straße in/an Wohngebiet',

  motorway_link: 'Autobahn-Zubringer/-anschlussstelle',
  trunk_link: 'Anschluss-/Verbindungsrampe einer Schnellstraße',
  primary_link: 'Anschlussstelle an Bundesstraße',
  secondary_link: 'Auf-/Abfahrt auf/von Landes-/Staats-/Kreisstraße',
  tertiary_link: 'Auf-/Abfahrt auf/von einfacher Kreisstraße',

  living_street: 'Verkehrsberuhigter Bereich',
  service: 'Erschließungsweg',
  pedestrian: 'Fußgängerzone/Weg oder Platz für Fußgänger',
  track: 'Wirtschafts-/Feld-/Waldweg',
  bus_guideway: 'Spurbus-Strecke',
  escape: 'Notbremsweg',
  raceway: 'Rennstrecke',
  road: 'Straße unbekannter Klassifikation',
  busway: 'Fahrbahn für Schnellbussysteme',

  footway: 'Gehweg',
  bridleway: 'Reitweg',
  steps: 'Treppen auf Fuß-/Wanderweg',
  corridor: 'Gang im Inneren eines Gebäudes',
  path: 'Weg/Pfad ohne vorgegebene Nutzungsart für Motorfahrzeuge',

  cycleway: 'Radweg',

  bus_stop: 'Bushaltestelle',
  crossing: 'Fußgängerüberweg',
  elevator: 'Aufzug',
  emergency_bay: 'Notrufnische/Nothaltebucht',
  emergency_access_point: 'Rettungspunkt',
  give_way: 'Vorfahrt Achten',
  milestone: 'Kilometrierung von Verkehrswegen',
  mini_roundabout: 'Mini-Kreisverkehr',
  motorway_junction: 'Autobahnausfahrt',
  passing_place: 'Ausweichstelle',
  platform: 'Bussteig',
  rest_area: 'Rastplatz',
  speed_camera: 'Geschwindigkeitsmessanlage',
  street_lamp: 'Straßenlaterne',
  services: 'Raststätte',
  stop: 'Stopp-Schild',
  traffic_mirror: 'Verkehrsspiegel',
  traffic_signals: 'Ampel',
  trailhead: 'Beginn/Ende eines Wanderweges',
  turning_circle: 'Wendestelle',
  turning_loop: 'Wendeschleife',
  toll_gantry: 'Schilderbrücke'
}

/**
 * Returns the name for a `surface` value.
 *
 * Based on Source https://wiki.openstreetmap.org/wiki/DE:Key:surface.
 *
 * @param {*} surface the value of the OSM way tag with the key `surface`
 * @returns the name
 */
export const surfaceType = (surface) => {
  return surfaceTypes[surface] || 'unbekannte Baustoffklasse'
}

/**
     * Returns the name for a `highway` value.
     * <p>
     * Based on Source https://wiki.openstreetmap.org/wiki/DE:Key:highway.
     *
     * @param {*} highway the value of the OSM way tag with the key `highway`
     * @returns the name
     */
export const highwayType = (highway) => {
  return highwayTypes[highway] || 'Unbekannte Straßenklasse'
}
