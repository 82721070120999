import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Button } from '@mui/material'
import {
  Login, PowerSettingsNew, CallSplit, Compare, PlayArrow, Delete, AccountCircle, Menu,
  InsertChart, Storage, FileDownload, FilterListOff, DirectionsBike, DirectionsCar,
  TwoWheeler, DirectionsBus, DirectionsSubway, DirectionsWalk, HelpOutline, PriorityHigh,
  Today, HourglassTop, PendingActions, BlurOn, Reorder, LinearScale, NavigateBefore,
  NavigateNext
} from '@mui/icons-material'
import { Colors } from './constants/Colors'

export const iconMap = {
  login: Login,
  power_settings_new: PowerSettingsNew,
  call_split: CallSplit,
  compare: Compare,
  play_arrow: PlayArrow,
  delete: Delete,
  account_circle: AccountCircle,
  menu: Menu,
  help_outline: HelpOutline,
  insert_chart: InsertChart,
  storage: Storage,
  file_download: FileDownload,
  filter_list_off: FilterListOff,
  directions_bike: DirectionsBike,
  directions_car: DirectionsCar,
  motorcycle: TwoWheeler,
  directions_bus: DirectionsBus,
  directions_subway: DirectionsSubway,
  directions_walk: DirectionsWalk,
  priority_high: PriorityHigh,
  today: Today,
  hourglass_top: HourglassTop,
  pending_actions: PendingActions,
  blur_on: BlurOn,
  reorder: Reorder,
  linear_scale: LinearScale,
  navigate_before: NavigateBefore,
  navigate_next: NavigateNext
}

/**
 * This button wrapper allows to turn different settings on or off.
 */
const ControlButton = ({
  text,
  icon,
  onClick,
  active,
  disabled,
  type,
  width = 'inherit',
  tooltip,
  margin = '0px 5px 5px 0px',
  visible = true,
  height = '32px',
  borderRadius = '3px',
  testid,
  sx,
  backgroundColor
}) => {
  // Local state
  // const [isTooltipOpen, setTooltipOpen] = useState(false)

  const handleClick = (event) => {
    // setTooltipOpen(false) // Close tooltip on click
    if (onClick) {
      onClick(event) // Trigger the provided onClick function
    }
  }

  const IconComponent = iconMap[icon]

  const commonProps = {
    onClick: handleClick,
    disabled,
    type,
    'data-testid': testid,
    sx: {
      width,
      height,
      margin,
      color: disabled || active ? '#FFFFFF' : Colors.Primary,
      backgroundColor: disabled
        ? '#B0B0B0' // Default grey for disabled buttons
        : active
          ? (backgroundColor || Colors.Primary) // Usw backgroundColor if injected
          : undefined,
      borderRadius,
      // Only change hover effect for !active and !disabled
      '&:hover': {
        backgroundColor: !active && !disabled ? '#f0f0f0' : undefined
      },
      ...sx
    }
  }

  if (!visible) return null

  const buttonVariant = disabled
    ? 'contained'
    : active
      ? 'contained'
      : 'outlined'

  const buttonColor = disabled
    ? 'grey'
    : active
      ? 'primary'
      : 'default'

  const buttonContent = text
    ? (
    <Button
      {...commonProps}
      variant={buttonVariant}
      color={buttonColor}
      startIcon={IconComponent ? <IconComponent /> : null}
    >
      {text}
    </Button>
      )
    : (
    <IconButton
      {...commonProps}
      sx={{
        ...commonProps.sx,
        border: '1px solid', // Apply border for outline
        borderColor: disabled ? '#B0B0B0' : Colors.Primary // Disabled or normal state
      }}
    >
      {IconComponent ? <IconComponent /> : null}
    </IconButton>
      )

  // Ensure the wrapper span is always part of the layout
  /* const wrappedButton = (
    <span style={{ display: 'inline-block', pointerEvents: disabled ? 'none' : 'auto' }}>
      {buttonContent}
    </span>
  ) */

  return buttonContent
  /* tooltip
    ? (
      <Tooltip
        title={tooltip}
        enterDelay={2000}
        disableTouchListener={disabled}
        disableHoverListener={disabled}
        open={isTooltipOpen}
        onOpen={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
      >
        {wrappedButton}
      </Tooltip>
      )
    : (
  buttonContent
  ) */
}

ControlButton.propTypes = {
  visible: PropTypes.bool,
  // undefined if no text just an icon is wanted
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string, // undefined if no icon is wanted
  onClick: PropTypes.func, // undefined for dummy buttons
  disabled: PropTypes.bool,
  type: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  active: PropTypes.bool, // undefined for buttons which cannot be "active"
  tooltip: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  testid: PropTypes.string,
  sx: PropTypes.object
}

export default ControlButton
