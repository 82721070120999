import { useState, useRef, useCallback } from 'react'

/**
 * useSocketManager - A custom hook to manage WebSocket connections.
 *
 * This hook provides an easy way to add, remove, and clear WebSocket connections while maintaining
 * a stable reference (`socketsRef`) to the current state of sockets. It ensures WebSocket handlers
 * always have access to the latest socket state and avoids unnecessary re-renders.
 *
 * Key Functions:
 * - `addSocket(newSocket)`: Adds a new WebSocket connection to the list and updates the state and
 *   reference.
 * - `removeSocket(socketToRemove)`: Removes a specific WebSocket from the list and updates the
 *   state and reference.
 * - `clearSockets()`: Closes all open WebSocket connections and clears the socket list. Memoized
 *   using `useCallback` to prevent re-render loops.
 *
 * Important:
 * - `socketsRef` is used to access the latest socket state within WebSocket event handlers `ws.on`,
 *   as React state updates are asynchronous and may not provide the current state immediately.
 * - `clearSockets` is memoized using `useCallback` to avoid triggering unnecessary re-renders in
 *   components.
 *
 * Example Usage:
 *
 * const { addSocket, removeSocket, clearSockets } = useSocketManager();
 *
 * @returns {Object} An object containing the `addSocket`, `removeSocket`, and `clearSockets` method
 */
const useSocketManager = () => {
  const [sockets, setSockets] = useState([])
  const socketsRef = useRef(sockets) // required to access current state in `ws.on` handlers

  const addSocket = useCallback((newSocket) => {
    const updatedSockets = [...socketsRef.current, newSocket]
    socketsRef.current = updatedSockets // Update the ref to reflect the current sockets
    setSockets(updatedSockets) // Update the state to trigger re-render if needed
  }, [])

  const removeSocket = useCallback((socketToRemove) => {
    const updatedSockets = socketsRef.current.filter(s => s !== socketToRemove)
    socketsRef.current = updatedSockets // Keep ref updated
    setSockets(updatedSockets) // Update state
  }, [])

  const clearSockets = useCallback(() => { // w/o useCallback endless re-rending is triggered
    socketsRef.current.forEach(socket => socket.close()) // Close all sockets
    socketsRef.current = [] // Clear the ref
    // Without this a warning is shown "cant perform React state update" (in `ws.onclose`)
    setSockets([]) // Clear the state
  }, [])

  return {
    // sockets,
    addSocket,
    removeSocket,
    clearSockets
  }
}

export default useSocketManager
