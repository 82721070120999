/**
 * Endpoint names offered by the API.
 *
 * @author Armin Schnabel
 */
export const Endpoints = {
  Users: (userName) => `users/${userName}`,

  Measurements: 'measurements',
  Locations: 'locations',
  Job: 'job',
  Jobs: 'jobs',
  H3: 'h3',
  Segments: 'segments',
  FinishedJobs: 'finished_jobs',
  Images: 'images'
}
