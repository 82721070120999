import { debug } from './login/utils.js'
import { Modes } from './sidebar/datasets/DatasetsAnalysis.js'
import { defaultErrorHandling } from './ErrorHandlingHelpers.js'
import { Sources } from './constants/Sources'
import normalize from '@mapbox/geojson-normalize'
import { setH3 } from '../reducers/h3'
import { setSegments } from '../reducers/segments'
import { apiSlice } from '../api/apiSlice'
import { overviewDataFromH3 } from './map/MapBoxHelpers'

/**
 * Commonly used functions that fetch segments, h3 and measurements data using axios.
 */

/**
 * Reloads the processed data if the response indicates that new data is available.
 *
 * @param {*} response The response to check.
 * @param {*} map The map to update, if necessary.
 * @param logout function to log user out if necessary.
 */
export const reloadProcessedDataIfNecessary = (response, map, logout, dispatch) => {
  const reloadNecessary = response.type === 'update' &&
    response.jobs.filter(j => j.status === 'FINISHED' && !j.viewed).length > 0
  if (reloadNecessary) {
    reloadProcessedData(response.jobs, map, logout, dispatch)
  }
}

/**
 * Reload h3 or segments to the map when a job execution is finished.
 *
 * @param {*} finishedJobs The finished jobs.
 * @param {*} map The map to update.
 * @param logout function to log user out if necessary.
 */
const reloadProcessedData = async (finishedJobs, map, logout, dispatch) => {
  if (debug()) {
    console.log('reloadProcessedData ...')
  }

  const anyHeatMapJob = finishedJobs.some(j => j.mode === Modes.H3)
  if (anyHeatMapJob) {
    const h3 = await getH3(dispatch, defaultErrorHandling, logout)
    dispatch(setH3(h3.features))
    map.getSource(Sources.H3).setData(h3)
    map.getSource(Sources.H3Overview).setData(overviewDataFromH3(h3))
  }

  const anySegmentJob = finishedJobs.some(j => j.mode === Modes.Segment)
  if (anySegmentJob) {
    const segmentsData = await getSegments(dispatch, defaultErrorHandling, logout)
    dispatch(setSegments(segmentsData.features))
    map.getSource(Sources.Segment).setData(normalize(segmentsData))
    const forwardData = await segmentsData.features.filter(
      feature => feature.properties.forward_moving)
    const backwardData = await segmentsData.features.filter(
      feature => !feature.properties.forward_moving)

    map.getSource(Sources.ForwardArrows).setData(normalize({
      type: 'FeatureCollection',
      features: forwardData
    }))
    map.getSource(Sources.BackwardArrows).setData(normalize({
      type: 'FeatureCollection',
      features: backwardData
    }))
  }
}

export const getUser =
async (dispatch, errorHandler, logout, userName) => {
  if (errorHandler === undefined) {
    throw new Error('getUser: No error handler defined')
  }
  const fromSlice = apiSlice.endpoints.getUser.initiate
  const result = await dispatch(fromSlice(userName))
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result
}

/**
   * Request segments data from API
   *
   * @param errorHandler function that handles the error.
   * @param logout function to log user out if necessary.
   * @return The data loaded.
   */
export const getSegments = async (dispatch, errorHandler, logout, setSegmentsLoaded = () => {}) => {
  if (errorHandler === undefined) {
    throw new Error('getSegment: No error handler defined')
  }
  const getSegmentsFromSlice = apiSlice.endpoints.getSegments.initiate
  const result = await dispatch(getSegmentsFromSlice())
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  setSegmentsLoaded(true)
  return result.data
}

export const deleteSegments =
  async (dispatch, errorHandler, logout, username) => {
    if (errorHandler === undefined) {
      throw new Error('deleteSegments: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.deleteSegments.initiate
    const result = await dispatch(fromSlice(username))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result.data
  }

export const deleteH3 =
    async (dispatch, errorHandler, logout, username) => {
      if (errorHandler === undefined) {
        throw new Error('deleteH3: No error handler defined')
      }
      const fromSlice = apiSlice.endpoints.deleteH3.initiate
      const result = await dispatch(fromSlice(username))
      if (result.error !== undefined) {
        errorHandler(result.error, logout)
        return
      }
      return result.data
    }

/**
 * Delete a measurement from the API.
 *
 * @param errorHandler function that handles the error.
 * @param dataset the object representing the measurement to delete
 * @param logout function to log user out if necessary.
 */
export const deleteMeasurement = async (
  dispatch,
  errorHandler,
  logout,
  dataset,
  enqueueSnackbar
) => {
  if (errorHandler === undefined) {
    throw new Error('deleteMeasurement: No error handler defined')
  }
  const deleteMeasurementFromSlice = apiSlice.endpoints.deleteMeasurement.initiate
  const result = await dispatch(deleteMeasurementFromSlice(dataset, enqueueSnackbar))
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result.data
}

export const createJob = async (dispatch, errorHandler, logout, dataset, pipeline, mode) => {
  if (errorHandler === undefined) {
    throw new Error('createJob: No error handler defined')
  }
  const body = {
    deviceId: dataset.deviceId,
    measurementId: parseInt(dataset.measurementId),
    pipeline,
    mode
  }
  const createJobFromSlice = apiSlice.endpoints.createJob.initiate
  const result = await dispatch(createJobFromSlice(body))
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result.data
}

export const updateMeasurementTag = async (dispatch, errorHandler, logout, item, tag) => {
  if (errorHandler === undefined) {
    throw new Error('updateMeasurementTag: No error handler defined')
  }
  const body = [{ op: 'update', tags: JSON.stringify(tag) }]
  const fromSlice = apiSlice.endpoints.updateMeasurement.initiate
  const result = await dispatch(fromSlice({ item, body }))
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result.data
}

export const replaceMeasurementTags = async (dispatch, errorHandler, logout, item, tags) => {
  if (errorHandler === undefined) {
    throw new Error('replaceMeasurementTags: No error handler defined')
  }
  const body = [{ op: 'replace', tags: JSON.stringify(tags) }]
  const fromSlice = apiSlice.endpoints.updateMeasurement.initiate
  const result = await dispatch(fromSlice({ item, body }))
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result.data
}

export const deleteMeasurementTag = async (dispatch, errorHandler, logout, item, tag) => {
  if (errorHandler === undefined) {
    throw new Error('deleteMeasurementTag: No error handler defined')
  }
  const body = [{ op: 'delete', tags: JSON.stringify({ [tag[0]]: tag[1] }) }]
  const fromSlice = apiSlice.endpoints.updateMeasurement.initiate
  const result = await dispatch(fromSlice({ item, body }))
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result.data
}

/**
   * Request measurements data from API
   *
   * @param errorHandler function that handles the error.
   * @param logout function to log user out if necessary.
   * @return The data loaded.
   */
export const getMeasurements = async (dispatch, errorHandler, logout) => {
  if (errorHandler === undefined) {
    throw new Error('getMeasurements: No error handler defined')
  }
  const getMeasurementsFromSlice = apiSlice.endpoints.getMeasurements.initiate
  const result = await dispatch(getMeasurementsFromSlice())
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result.data
}

/**
   * Request h3 data from API
   *
   * @param errorHandler function that handles the error.
   * @param logout function to log user out if necessary.
   * @return The data loaded.
   */
export const getH3 = async (dispatch, errorHandler, logout, setH3Loaded = () => {}) => {
  if (errorHandler === undefined) {
    throw new Error('getH3: No error handler defined')
  }
  const getH3FromSlice = apiSlice.endpoints.getH3.initiate
  const result = await dispatch(getH3FromSlice())
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  setH3Loaded(true)
  return result.data
}

/**
   * Request location data from API
   *
   * @param errorHandler function that handles the error.
   * @param logout function to log user out if necessary.
   * @param dataset The dataset to load the locations for.
   * @return The data loaded.
   */
export const getGeoLocations = async (dispatch, errorHandler, logout, dataset) => {
  if (errorHandler === undefined) {
    throw new Error('getGeoLocations: No error handler defined')
  }
  const getGeoLocationsFromSlice = apiSlice.endpoints.getGeoLocations.initiate
  const result = await dispatch(getGeoLocationsFromSlice(dataset))
  if (result.error !== undefined) {
    errorHandler(result.error, logout)
    return
  }
  return result.data
}
