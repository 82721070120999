import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ControlButton from '../../ControlButton'
import { isStatusOngoing, Status } from '../../JobHelpers.js'
import { States, updateDatasetsView } from '../../../reducers/datasetsView'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { addJobs } from '../../../reducers/jobs'
import { addJobsToMeasurements } from '../../../reducers/measurements'
import { createJob } from '../../DataApi.js'

/**
 * The Pipelines which can be passed to the `jobs` endpoint.
 */
export const Pipelines = {
  Surface: 'surface',
  Delete: 'delete',
  MapMatching: 'map-matching'
}

/**
 * The Modes which can be passed to the `jobs` endpoint.
 */
export const Modes = {
  H3: 'h3',
  Segment: 'segment',
  SegmentHistory: 'measurement_based_segment',
  Measurement: 'measurement',
  None: 'none' // assuming Pipelines.MapMatching has no sub-mode
}

/**
 * This component handles the analysis selection.
 */
const DatasetsAnalysis = ({ logout, active, selected }) => {
  // Stateless hooks
  const dispatch = useDispatch()

  // Local state
  const [pipeline, setPipeline] = useState(null) // analysis type (surface|map-matching)
  const [mode, setMode] = useState(null) // output format relevant for pipeline=surface: segment|h3

  // Redux state
  const jobsById = useSelector((state) => state.jobs.entities)
  const measurementsById = useSelector((state) => state.measurements.entities)

  const setActive = (active) => {
    dispatch(updateDatasetsView({ active }))
  }

  const clearSelected = () => {
    dispatch(updateDatasetsView({ selected: [] }))
  }

  /**
   * Filters the given selected items array to only process unique jobs,
   * i.e. no two unfinished job with the same pipeline, mode and dataset should
   * be in process at the same time.
   *
   * @param selectedIds The ids of the items to filter.
   * @return The filtered items.
   */
  const processibleItems = (selectedIds) => {
    const processibleItems = selectedIds.filter(id => {
      const measurement = measurementsById[id]
      const jobs = measurement.jobs.map(jobId => jobsById[jobId])
      const hasRunningJobs = jobs.some(job => {
        const nonfinalStatus = isStatusOngoing(job.status)
        return job.pipeline === pipeline && job.mode === mode && nonfinalStatus
      })
      return !hasRunningJobs
    })
    return processibleItems
  }

  /**
   * A factory that generates a callback function that creates a job given dataset id.
   * Used to generate the callback function when looping over the ids.
   * It can create different callback functions for Modes.SegmentHistory and Modes.Segment.
   *
   * @param mode The mode of the job.
   * @return A function that takes a dataset id, posts job request and returns the job.
   */
  const createJobForID = (selectedMode) => {
    return async (id) => {
      const measurement = measurementsById[id]
      const response = await createJob(
        dispatch, defaultErrorHandling, logout, measurement, pipeline, selectedMode
      )
      return {
        id: response.jobId,
        deviceId: measurement.deviceId,
        measurementId: parseInt(measurement.measurementId),
        status: Status.Created,
        created: new Date().toISOString(),
        pipeline,
        mode: selectedMode
      }
    }
  }

  /**
   * Sends async requests to create jobs, depending on the pipeline mode.
   *
   * @param ids The ids of the selected measurements.
   * @return An array of 1 or 2 arrays with promises which resolve to the created jobs.
   */
  const sendJobCreationRequests = async (ids) => {
    const isSegmentMode = mode === Modes.Segment

    // Return both the segment and segment history jobs.
    if (isSegmentMode) {
      // Resolves to [ [$segmentJobs], [$segmentHistoryJobs] ]
      return await Promise.all([
        Promise.all(ids.map(createJobForID(mode))),
        Promise.all(ids.map(createJobForID(Modes.SegmentHistory)))
      ])
      // Return mode related jobs.
    } else {
      return [await Promise.all(ids.map(createJobForID(mode)))]
    }
  }

  /**
   * Send job creation requests to API.
   *
   * @param {*} ids The ids of the items to create jobs for.
   */
  const createJobs = async (ids) => {
    try {
      // Add created jobs instead of waiting for job updates for a reactive UIX
      const createdJobsArrays = await sendJobCreationRequests(ids)

      createdJobsArrays.forEach(createdJobs => {
        const createdJobsIds = createdJobs.map(j => j.id)
        dispatch(addJobs(createdJobs))
        dispatch(addJobsToMeasurements(ids, createdJobsIds))
      })
    } catch (error) {
      defaultErrorHandling(error, logout)
    } finally {
      // Leave "dataset analysis" mode where the user can select items to analyse
      setActive(States.ShowDatasets)
    }
  }

  /**
   * Handler which is called when selected items are sent to be analyzed.
   */
  const onSubmit = async () => {
    setActive(States.SubmittingAnalyzeJobs)
    const ids = processibleItems(selected)
    clearSelected()
    await createJobs(ids)
  }

  /**
   * Changes the selected pipeline and mode.
   *
   * @param {*} pipeline The pipeline the user selected.
   * @param {*} mode The pipeline the user selected.
   */
  const select = (pipeline, mode) => {
    setPipeline(pipeline)
    setMode(mode)
    setActive(States.SelectAnalyzeDatasets)
  }

  // These functions below are not defined inline so that they
  // are not redefined at each render and cause child components to
  // also re-render. See RFR-309.
  const onClickSelectSegmentAnalysis = () => {
    select(Pipelines.Surface, Modes.Segment)
  }

  const onClickSelectH3Analysis = () => {
    select(Pipelines.Surface, Modes.H3)
  }

  /**
   * Handler which is called when the analysis selection mode is disabled.
   */
  const onClickCancel = () => {
    setActive(States.ShowDatasets)
    clearSelected()
  }

  return (
    <div>
      { /* Choose analysis modes buttons */ }
      {(active === States.ShowDatasets ||
        active === States.SelectRemoveDatasets ||
        active === States.DeleteDatasets) && (
        <div style={{ marginTop: '5px' }}>
          Straßenqualität analysieren<br />
          <ControlButton
            text="Segmente"
            icon="reorder"
            onClick={onClickSelectSegmentAnalysis}
            disabled={
              active === States.SelectAnalyzeDatasets ||
              active === States.SelectRemoveDatasets ||
              active === States.DeleteDatasets
            }
          />
          <ControlButton
            text="Heatmap"
            icon="blur_on"
            onClick={onClickSelectH3Analysis}
            disabled={
              active === States.SelectAnalyzeDatasets ||
              active === States.SelectRemoveDatasets ||
              active === States.DeleteDatasets
            }
          />
        </div>
      )}

      { /* Submit/abort datasets selection */ }
      <div style={{
        display: active === States.SelectAnalyzeDatasets ||
        active === States.SubmittingAnalyzeJobs
          ? 'inline'
          : 'none'
      }}>
        <ControlButton
          onClick={onSubmit}
          icon="send"
          text={(mode === Modes.H3 ? 'Heatmap' : 'Segmente') + ' beauftragen'}
          active={true}
          disabled={selected.length === 0 || active === States.SubmittingAnalyzeJobs}
        />

        <ControlButton
          onClick={onClickCancel}
          icon="cancel" // Icon musst du eventuell auch in iconMap hinzufügen
          text="Abbrechen"
          active={false}
          disabled={active === States.SubmittingAnalyzeJobs}
        />
      </div>
    </div>
  )
}

DatasetsAnalysis.propTypes = {
  logout: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired
}

export default DatasetsAnalysis
