import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Header from './header/Header'
import RFRHeader from './header/RFRHeader'
import ViewControls from './sidebar/ViewControls'
import Sidebar from './sidebar/Sidebar'
import Footer from './Footer'
import SegmentDetails from './details/SegmentDetails'
import DatasetDetails from './details/DatasetDetails'
import DetailsBox from './details/DetailsBox'
import {
  getLocalStorage, autoLogin, setLocalStorage,
  getConfig, mockAuth, errorTrackingAccepted
} from './login/utils.js'
import DatasetsView from './sidebar/datasets/DatasetsView'
import InfrastructureView from './sidebar/infrastructure/InfrastructureView'
import ExportView from './sidebar/export/ExportView'
import { Sources } from './constants/Sources'
import { Styles } from './constants/Styles'
import { Views } from './constants/Views'
import { Directions } from './constants/Directions'
import { Modalities } from './sidebar/Modality'
import SidebarSkeleton from './sidebar/SidebarSkeleton'
import { updateUi, setVisibleLayerId } from '../reducers/ui'
import { LocalStorage } from './constants/LocalStorage'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import MapContainer from './map/MapContainer.js'
import useSocketManager from '../hooks/useSocketManager'
import Profile from './header/Profile.js'

const Dashboard = ({ style, defaultLocation, accessToken }) => {
  // Stateless Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate() // To forward the user, e.g. on auth error
  const { clearSockets } = useSocketManager() // stateless unless you extract `sockets`

  // Local state - DO NOT ADD states which change regularly (and trigger children re-rendering)
  const [map, setMap] = useState(null) // is only set once
  const [width, setWidth] = useState(0) // only changes on viewport re-sizing

  const sidebarLess = style === Styles.RFR

  /**
   * Effects which depend on no state/prop (i.e. only executed on un-/mount).
   *
   * The first part is called when the component is inserted into the DOM.
   * The returned function is called when the component is removed from the DOM.
   *
   * Checks login, the mapbox-map setup including the onClick etc. handlers.
   * Loads and sets the API-QualitySegment data asynchronously.
   */
  useEffect(() => {
    // Required as it's not allowed to useEffect(async ()...
    // We cannot blockingly wait for `autoLogin` in `useEffect` but when the token is inactive we
    // don't want `MapContainer.loadData()` to fire and show errors. Unfortunately, we werent able
    // to fix this using the response from `await autoLogin`. [BIK-1083]
    // Using `async` in `useEffect` as in the other `web-app` also does not fix this.
    const initialize = async () => {
      // Check if user is logged in otherwise redirect to Login Page
      if (!await autoLogin(navigate, '/map', '/', dispatch, logout)) {
        return
      }

      if (errorTrackingAccepted()) {
        initializeSentry()
      }
    }

    /**
     * Update internal viewport size state
     */
    function updateWindowDimensions () {
      setWidth(window.innerWidth)
    }

    // Register to viewport size changes
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    initialize() // async stuff

    // Cleanup (component did unmount)
    return () => {
      // Unregister to viewport size changes
      window.removeEventListener('resize', updateWindowDimensions)
    }
    // eslint-disable-next-line
  }, []) // effect depends on no state/props: only run on un-/mount, not re-render

  /**
   * Initialize Sentry error tracking. Should happen as early as possible in the lifecycle.
   * See https://docs.sentry.io/platforms/javascript/guides/react/.
   *
   * This reports any uncaught exceptions triggered by our app to Sentry.
   *
   * Further configuration is possible, like filtering event data forwarded to sentry:
   * https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
   */
  const initializeSentry = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT
    // if (isProductionEnvironment() /* && process.env.REACT_APP_SENTRY_RELEASE */) {
    Sentry.init({
      // Sentry DSN. This ids the account but is not a secret (public key).
      dsn: 'https://63004f2e74e642f88d97579f917cdc3e@o418976.ingest.sentry.io/4504248642764800',

      // For more integrations like router, redux, etc. see
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/
      integrations: [new BrowserTracing()],

      // Should always be `production`. Sentry also adds the `url` to identify e.g. staging.
      environment,

      // This release name is also used in `sentry.js` to upload the source-map
      release: process.env.REACT_APP_SENTRY_RELEASE,

      // Reduce sample rates if we get close to free tier (50T errors, 100T transactions).
      // Captures 100% of errors
      sampleRate: 1.0,
      // Captures 0-100% of transactions for performance tracing.
      // Performance tracing is only enabled on staging right now.
      tracesSampler: () => {
        if (window.location.host === 'staging.cyface.de') {
          return 1.0
        } else {
          return 0.0
        }
      }
    })
    // }
  }

  /**
   * Handler for on-logout clicks by the user.
   *
   * `LoginForm.js` contains a copy of this function and should be kept in sync.
   */
  const logout = useCallback(() => { // inline-function memoized
    /**
     * ResetView fix.
     *
     * Fixes wrong map layers shown when a different tab is selected before logout.
     *
     * The logic here is similar to the one in `ViewControl.onClick()`.
     */
    dispatch(updateUi({ view: Views.Datasets }))

    // Switch dataset on map
    dispatch(setVisibleLayerId(map, Sources.Datasets, Directions.Forward, Modalities.All))

    // Close all open sockets
    clearSockets()

    // Clear local storage
    const errorTrackingAccepted = getLocalStorage(LocalStorage.ErrorTrackingAccepted)
    const configuration = getLocalStorage(LocalStorage.AuthServiceConfiguration)
    const endSessionUrl = configuration.endSessionEndpoint
    const idToken = getLocalStorage(LocalStorage.IdToken)
    localStorage.clear()
    // Set terms-accepted flag so the used does not have to accept it again on the next login
    setLocalStorage(LocalStorage.TermsAccepted, true)
    setLocalStorage(LocalStorage.ErrorTrackingAccepted, errorTrackingAccepted)

    // Inform the auth server that the user wants to end the session
    if (mockAuth()) {
      console.log('Mocking logout ...')
      window.location.href = '/'
    } else {
      const redirectUri = getConfig().redirectUri
      const u = `${endSessionUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`
      window.location.href = u
      // The auth server will then redirect the user to `Callback` which navigates the user to `/`
    }

    // Prevent default handling of the link-click
    return false
  }, [dispatch, map, clearSockets])

  // This threshold is coded into the Sidenav button, so far we were not able to overwrite it
  const mobileView = !sidebarLess ? width < 993 : true

  const styledHeader = useMemo(() => style === Styles.Cyface // inline-function memoized
    ? <Header
        mobileView={mobileView}
        right={<Profile logout={logout} style={style} />}
      />
    : style === Styles.RFR
      ? <RFRHeader
        right={<Profile logout={logout} style={style} />}
      />
      : '' // style if null until it's loaded from config
  , [style, logout, mobileView])

  // Injecting into the views or else the mobile view on screens < 400px width breaks [DAT-1466]
  const viewControls = useMemo(() => <ViewControls map={map} />, [map]) // inline-element memoized

  return (
      <div>
        {
          map
            ? (<>
              { styledHeader }

              {!sidebarLess
                ? <Sidebar mobileView={mobileView}>
                    <DatasetsView map={map} viewControls={viewControls} logout={logout} />
                    <InfrastructureView map={map} viewControls={viewControls} logout={logout} />
                    <ExportView viewControls={viewControls} />
                  </Sidebar>
                : '' }

              <DetailsBox mobileView={mobileView}>
                <SegmentDetails mobileView={mobileView} map={map} logout={logout} />
                <DatasetDetails />
              </DetailsBox>
            </>)

            // Showing skeletons during initial loading of the map
            : (<>
              { styledHeader }

              { !sidebarLess
                ? <Sidebar mobileView={mobileView}>
                    <SidebarSkeleton/>
                  </Sidebar>
                : '' }
            </>)
        }
        {(
          <MapContainer
            map={map}
            setMap={setMap}
            logout={logout}
            mobileView={mobileView}
            accessToken={accessToken}
            defaultLocation={defaultLocation}
            sidebarLess={sidebarLess}
            style={style}
            width={width}
          />
        )}
        <Footer left={mobileView ? '110px' : 'auto'} right={mobileView ? 'auto' : '310px'} />
      </div>
  )
}

Dashboard.propTypes = {
  accessToken: PropTypes.string.isRequired,
  defaultLocation: PropTypes.array.isRequired,
  style: PropTypes.string.isRequired
}

export default Dashboard
