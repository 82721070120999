import React from 'react'
import GenericIcon from '../GenericIcon'
import { Colors } from '../../constants/Colors'

export const datesAreOnSameDay = (firstDate, secondDate) => {
  return firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
}

export const nbsp = '\u00A0'

export const todayIcon = (multiUser) => {
  return <GenericIcon
    position="absolute"
    tooltip='ausblenden'
    margin= {multiUser ? '-2px 0px 0px -1px' : '-7px 0px 0px -4px'}
    icon='today' // expand_less
    color={Colors.Neutral}
  />
}

export const groupNameTranslater = {
  today: 'Heute',
  lastWeek: 'Letzte Woche',
  thisMonth: 'Diesen Monat',
  thisYear: 'Dieses Jahr'
}

/**
   * Groups the measurements by date.
   * The groups are: today, lastWeek, thisMonth, thisYear, and the years before.
   *
   * @param {*} items The measurements of a user.
   * @returns an object with the groups as keys and the measurements as values.
   */
export const groupItemsByDate = (items) => {
  const today = new Date()
  const groupedItems = items.reduce((groups, item) => {
    const itemDate = new Date(item.startTimestamp)
    if (datesAreOnSameDay(itemDate, today)) {
      groups.today.push(item)
    } else if (itemDate.getTime() > new Date().setDate(today.getDate() - 7)) {
      groups.lastWeek.push(item)
    } else if (itemDate.getMonth() === today.getMonth() &&
                   itemDate.getFullYear() === today.getFullYear()) {
      groups.thisMonth.push(item)
    } else if (itemDate.getFullYear() === today.getFullYear()) {
      groups.thisYear.push(item)
    } else {
      const itemYear = itemDate.getFullYear()
      if (!groups[itemYear]) {
        groups[itemYear] = []
      }
      groups[itemYear].push(item)
    }
    return groups
  }, { today: [], lastWeek: [], thisMonth: [], thisYear: [] })

  return groupedItems
}
