/**
 * Allows to access the Snackbar from helper files without a functional context.
 */
let enqueueSnackbar = null

export const setSnackbarHandler = (enqueue) => {
  enqueueSnackbar = enqueue
}

export const showSnackbar = (message, options) => {
  if (enqueueSnackbar) {
    enqueueSnackbar(message, options)
  } else {
    console.error('Snackbar handlers not set')
  }
}
