import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import LogoutButton from './LogoutButton'
import ControlButton from '../ControlButton'
import axios from 'axios'
import { asyncRefreshTokenIfRequired, getLocalStorage, getApiUrl, debug, supportEmail } from '../login/utils'
import { Endpoints } from '../constants/Endpoints'
import { LocalStorage } from '../constants/LocalStorage'
import { useSnackbarContext } from '../SnackbarContext.js'
import { Styles } from '../constants/Styles.js'

/**
 * The profile icon usually shown in the top right of the header.
 *
 * *Attention:*
 * Do not implement profile features like changing passwort, and email.
 * Keycloak offers a page where the user can do all these things.
 * But Account deletion happens here directly as we also need to delete the user data.
 */
const Profile = ({ logout, style, right, top }) => {
  // Stateless Hooks
  const navigate = useNavigate() // To forward the user, e.g. on auth error
  const { enqueueSnackbar } = useSnackbarContext()

  // Local state
  const [showProfileIcon, setShowProfileIcon] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [showFinalWarning, setShowFinalWarning] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const username = getLocalStorage(LocalStorage.Username)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const confirmDeleteAccount = () => {
    setShowProfileIcon(false)
    setShowMenu(false)
    setShowWarning(true)
  }

  const handleDeleteAccount = () => {
    setShowFinalWarning(true)
    setShowWarning(false)
    setShowMenu(false)
  }

  const cancelDeleteAccount = () => {
    setShowWarning(false)
    setShowMenu(false)
    setShowProfileIcon(true)
  }

  const cancelFinalDeleteAccount = () => {
    setShowFinalWarning(false)
    setShowProfileIcon(true)
  }

  const deleteAccountNow = async () => {
    setDeleting(true)

    // Send Account Deletion Request
    await asyncRefreshTokenIfRequired()
    const headers = { Authorization: 'Bearer ' + getLocalStorage(LocalStorage.AccessToken) }
    const userId = getLocalStorage(LocalStorage.UserId)

    // Synchronous call to wait for the result
    try {
      const response = await axios.delete(`${getApiUrl()}${Endpoints.Users(userId)}`, { headers })
      if (response.status === 202) {
        localStorage.clear()
        navigate('/')
        return true
      } else {
        throw Error('User deletion request not successful: ' + response.status)
      }
    } catch (error) {
      if (debug()) {
        console.log('User deletion failed: ' + error)
      }
      const message = 'Nutzer löschen fehlergeschlagen. Bitte laden Sie die Seite neu.  ' +
      'Falls der Zustand anhält bitte ' + supportEmail() + ' kontaktieren.'
      enqueueSnackbar(message)
      return false
    }
  }

  return (
    <div>
    {showProfileIcon && (
      <ControlButton
        onClick={ toggleMenu }
        icon="account_circle"
        active={showMenu}
        text="Profil"
        margin='0px 0px 0px 0px'
        tooltip="Nutzerprofil"
      />
    )}
    {showMenu && (
      <Menu right={right} top={top} height={style === Styles.Cyface ? '78px' : '47px'}>
        <p>
          {username} (<a onClick={confirmDeleteAccount}>Account löschen</a>)
        </p>
        <LogoutButton logout={logout} />
      </Menu>
    )}
    {showWarning && (
      <Warning
        right={right}
        top={top}
        height={style === Styles.Cyface ? '69px' : '44px'}
        background={style === Styles.Cyface ? 'white' : 'rgb(216, 216, 216)'}
      >
        <p>Sind Sie sich sicher, dass Sie ihren Account löschen wollen?</p>
        <ControlButton
          onClick={handleDeleteAccount}
          margin="0 10px 0 0"
          width="200px"
          active={true}
          backgroundColor="red"
          text="Daten löschen" />
        <ControlButton
          onClick={cancelDeleteAccount}
          margin="0 0 0 0"
          width="105px"
          active={false}
          text="Abbrechen" />
      </Warning>
    )}
    {showFinalWarning && (
      <FinalWarning
        right={right}
        top={top}
        height={style === Styles.Cyface ? '69px' : '44px'}
        background={style === Styles.Cyface ? 'white' : 'rgb(216, 216, 216)'}
      >
        <ControlButton
          onClick={deleteAccountNow}
          margin="0 10px 0 0"
          width="360px"
          active={!deleting}
          disabled={deleting}
          backgroundColor="red"
          text="Daten und Account unwiderruflich löschen" />
        <ControlButton
          onClick={cancelFinalDeleteAccount}
          margin="0"
          width="105px"
          active={false}
          disabled={deleting}
          text="Abbrechen" />
      </FinalWarning>
    )}
    </div>
  )
}

const Menu = styled('div')(({ right, top, height }) => ({
  backgroundColor: 'white',
  color: '#777777',
  position: 'absolute',
  top: top || '-1px',
  right: right || '130px',
  width: '650px',
  padding: '20px 10px',
  height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  p: {
    margin: 0,
    paddingRight: '10px'
  },
  a: {
    color: 'red',
    cursor: 'pointer'
  }
}))

const Warning = styled('div')(({ height, right, top, background }) => ({
  position: 'absolute',
  top: top || '0px',
  right: right || '13px',
  width: '700px',
  padding: '10px',
  height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  background,
  zIndex: 2,
  p: {
    margin: 0,
    paddingRight: '10px',
    float: 'left'
  }
}))

const FinalWarning = styled('div')(({ height, right, top, background }) => ({
  position: 'absolute',
  top: top || '0px',
  right: right || '13px',
  width: '500px',
  padding: '10px',
  height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  background,
  zIndex: 2,
  p: {
    margin: 0,
    paddingRight: '10px',
    float: 'left'
  }
}))

Profile.propTypes = {
  logout: PropTypes.func.isRequired,
  style: PropTypes.string.isRequired,
  right: PropTypes.string,
  top: PropTypes.string
}

export default Profile
