import React from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton, InputAdornment } from '@mui/material'
import { DatePicker as MUIDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import deLocale from 'date-fns/locale/de'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { styled } from '@mui/material/styles'

/**
 * To selecte a Date (day).
 *
 * @author Armin Schnabel
 */
const DatePicker = ({ defaultDate, label, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <StyledDatePicker
        label={label}
        value={defaultDate || null}
        onChange={onChange}
        inputFormat="dd.MM.yyyy"
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CalendarTodayIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
            variant="outlined"
          />
        )}
      />
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  defaultDate: PropTypes.oneOfType([
    PropTypes.object, // Date object
    PropTypes.number // None is represented as -1
  ]),
  container: PropTypes.string
}

const StyledDatePicker = styled(MUIDatePicker)(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    color: '#3F8730'
  },
  '& .Mui-selected': {
    backgroundColor: '#3F8730'
  },
  '& .MuiPickersDay-dayOutsideMonth': {
    color: '#3F8730'
  },
  '& .MuiButtonBase-root': {
    color: '#3F8730'
  }
}))

export default DatePicker
