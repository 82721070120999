import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { styled } from '@mui/material/styles'
import ControlButton from '../../ControlButton'
import { States, updateDatasetsView } from '../../../reducers/datasetsView'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { deleteMeasurement } from '../../DataApi'
import { useSnackbarContext } from '../../SnackbarContext'

/**
 * This component handles the removal of datasets from the UI and requests deletion from the db.
 */
const DatasetsRemoval = ({ logout, active, selected }) => {
  // Stateless Hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbarContext()

  // Redux state
  const measurementsById = useSelector((state) => state.measurements.entities)

  // Local state
  const [open, setOpen] = React.useState(false)

  const setActive = (active) => {
    dispatch(updateDatasetsView({ active }))
  }

  const clearSelected = () => {
    dispatch(updateDatasetsView({ selected: [] }))
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  /**
   * Handler which is called when user confirms deletion of the selected datasets.
   *
   * @param {*} e the corresponding event
   */
  const onClickDeleteFinal = async () => {
    setActive(States.DeleteDatasets)
    await Promise.all(
      selected.map(async selectedId => {
        const dataset = measurementsById[selectedId]
        await deleteMeasurement(dispatch, defaultErrorHandling, logout, dataset, enqueueSnackbar)
      })
    )
    clearSelected()
    setActive(States.ShowDatasets)
    handleClose()
  }

  /**
   * Handler which is called when the dataset removal mode is exited.
   *
   * @param {*} e the corresponding event
   */
  const onClickClose = (e) => {
    e.preventDefault()
    setActive(States.ShowDatasets)
    clearSelected()
    handleClose()
  }

  /**
   * The container injected into the DOM.
   */
  return (
    <Container>
      <Box sx={{ display: active !== States.ShowDatasets ? 'none' : 'block' }}>
        <ControlButton
          icon="playlist_remove"
          margin='0px 0px 0px 0px'
          onClick={() => setActive(States.SelectRemoveDatasets)}
          text="Datensätze Löschen"
        />
      </Box>

      { /* Choose analysis modes buttons */ }
      { active === States.ChooseRemovalMode || active === States.SelectRemoveDatasets
        ? (
        <Box>
          <ControlButton
            margin='0px 5px 0px 0px'
            backgroundColor='#FF2600'
            active={true}
            text="Endgültig Löschen"
            onClick={handleClickOpen}
            disabled={selected.length === 0}
          />
          <ControlButton
            margin='0px 5px 0px 0px'
            backgroundColor='#999999'
            text="Abbrechen"
            onClick={onClickClose}
          />

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="delete-confirmation-dialog"
          >
            <DialogTitle id="delete-confirmation-dialog">Endgültig Löschen?</DialogTitle>
            <DialogActions sx={{ padding: '10px' }}>
              <ControlButton
                text="Bestätigen"
                onClick={onClickDeleteFinal}
                active={true}
                sx={{ marginLeft: '5px' }}
                tooltip="Löschen bestätigen"
              />
              <ControlButton
                text="Abbrechen"
                onClick={onClickClose}
                tooltip="Abbrechen"
              />
            </DialogActions>
          </Dialog>
        </Box>
          )
        : null }
    </Container>
  )
}

DatasetsRemoval.propTypes = {
  logout: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired
}

const Container = styled(Box)({})

export default DatasetsRemoval
