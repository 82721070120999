import { useState, useRef } from 'react'
import { Sources } from '../constants/Sources'
import { useSelector } from 'react-redux'
import { updateInfrastructureView } from '../../reducers/infrastructureView'
import { Views } from '../constants/Views'
import { updateDatasetsView } from '../../reducers/datasetsView'

const useMapFeatureHandlers = () => {
  // Redux state
  const view = useSelector((state) => state.ui.view)
  const selected = useSelector((state) => state.datasetsView.selected)
  const hoveredWayId = useSelector((state) => state.infrastructureView.hoveredWayId)
  const clickedWayId = useSelector((state) => state.infrastructureView.clickedWayId)

  // Local state
  // Keep a handler-references to unregister them in exitEditMode
  const [handlers, setHandlers] = useState({
    segmentMouseMoveHandler: null,
    segmentMouseLeaveHandler: null,
    segmentClickHandler: null,
    mapClickHandler: null
  })

  // References - required inside handlers to access the current local state
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const viewContainer = useRef()
  viewContainer.current = view
  const selectedContainer = useRef() // DatasetsView
  selectedContainer.current = selected
  const hoveredWayIdContainer = useRef() // InfrastructureView
  hoveredWayIdContainer.current = hoveredWayId
  const clickedWayIdContainer = useRef() // InfrastructureView
  clickedWayIdContainer.current = clickedWayId

  /**
   * Shows the user that no feature is hovered on the map.
   */
  const createSegmentMouseLeaveHandler = (map, dispatch) => (e) => {
    // Reset cursor
    map.getCanvas().style.cursor = ''

    changeHoveredWayId(null, dispatch, map)
  }

  /**
   * Shows the user which feature is hovered on the map.
   */
  const createSegmentMouseMoveHandler = (map, dispatch) => (e) => {
    const targetWay = e.features[0]

    map.getCanvas().style.cursor = 'pointer'

    changeHoveredWayId(targetWay.id, dispatch, map)
  }

  /**
   * Changes color of the clicked segment
   */
  const createSegmentClickHandler = (map, dispatch) => (e) => {
    const targetWay = e.features[0]

    changeHoveredWayId(null, dispatch, map)
    changeClickedWayId(targetWay.id, dispatch, map)
  }

  /**
   * Resets currently selected map features.
   */
  const createMapClickHandler = (
    map,
    selectedContainer,
    clickedWayIdContainer,
    viewContainer,
    dispatch
  ) => (e) => {
    const view = viewContainer.current
    if (view === Views.Datasets) {
      const selected = selectedContainer.current
      if (selected[0]) {
        dispatch(updateDatasetsView({ selected: selected.slice(1) }))
      }
    } else if (view === Views.Infrastructure) {
      const clickedWayId = clickedWayIdContainer.current
      if (clickedWayId) {
        changeClickedWayId(null, dispatch, map)
      }
    }
  }

  const setMapMouseHandlers = (map, dispatch) => {
    // If we access `onClickHandler` etc. via `this.state.onClickHandler` the handler is not found
    // used only in analysisView
    const segmentMouseMoveHandler = createSegmentMouseMoveHandler(map, dispatch)
    const segmentMouseLeaveHandler = createSegmentMouseLeaveHandler(map, dispatch)
    const segmentClickHandler = createSegmentClickHandler(map, dispatch)
    // Used in both, datasetsView and analysisView
    const mapClickHandler = createMapClickHandler(
      map,
      selectedContainer,
      clickedWayIdContainer,
      viewContainer,
      dispatch
    )

    // Keep a handler-references to unregister them in exitEditMode
    setHandlers({
      segmentMouseMoveHandler,
      segmentMouseLeaveHandler,
      segmentClickHandler,
      mapClickHandler
    })

    // Register handler
    map.on('click', mapClickHandler) // register before segment click, else clicked segment is reset
    map.on('click', Sources.Segment, segmentClickHandler)
    // "mousemove" fixes hover between two segments without leaving one
    map.on('mousemove', Sources.Segment, segmentMouseMoveHandler)
    map.on('mouseleave', Sources.Segment, segmentMouseLeaveHandler)
  }

  /**
   * Calculates the middle point between two coordinates
   * Source: http://www.movable-type.co.uk/scripts/latlong.html [MIT licence on github]
   *
   * @param {*} lng1 the longitude of the first coordinate
   * @param {*} lat1 the latitutde of the first coordinate
   * @param {*} lng2 the longitude of the second coordinate
   * @param {*} lat2 the latitutde of the second coordinate
   * /
  const calculateCenter = (lng1, lat1, lng2, lat2) => {
    const lngDiff = toRad(lng2 - lng1)
    lat1 = toRad(lat1)
    lat2 = toRad(lat2)
    lng1 = toRad(lng1)
    const bY = Math.cos(lat2) * Math.sin(lngDiff)
    const bX = Math.cos(lat2) * Math.cos(lngDiff)
    const lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX)
    const lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2),
      Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY))
    return [toDeg(lng3), toDeg(lat3)]
  }

  /**
   * Returns the 'radius' value of a number.
   *
   * @param {*} number to calculate the radius for
   * @returns the 'radius' value
   * /
  const toRad = (number) => {
    return number * Math.PI / 180
  }

  /**
   * Returns the 'degree' value of a number.
   *
   * @param {*} number to calculate the degree value for
   * @returns the 'degree' value
   * /
  const toDeg = (number) => {
    return number * (180 / Math.PI)
  } */

  /**
   * Adds an image to the map which can then be used later on the map, e.g. as marker.
   *
   * Attention: This is done asynchronously!
   *
   * @param map The map object to be updated
   * @param image the image to be used as symbol
   * @param imageId the id under which the image should be added to the map
   * @returns a {@code Promise} with a rejection(error) or resolve(void) on completion
   * /
  const asyncAddImage = (map, image, imageId, callback) => {
    return new Promise(function (resolve, reject) {
      map.loadImage(image, (error, image) => {
        if (error) {
          return reject(error)
        }
        // Add image to map
        map.addImage(imageId, image)
        // Resolve the promise
        return resolve()
      })
    })
  } */

  const changeHoveredWayId = (newWayId, dispatch, map) => {
    dispatch(updateInfrastructureView({ update: { hoveredWayId: newWayId }, map }))
  }

  const changeClickedWayId = (newWayId, dispatch, map) => {
    dispatch(updateInfrastructureView({ update: { clickedWayId: newWayId }, map }))
  }

  return {
    setMapMouseHandlers,
    createSegmentMouseMoveHandler,
    createSegmentMouseLeaveHandler,
    createSegmentClickHandler,
    createMapClickHandler,
    handlers
  }
}

export default useMapFeatureHandlers
