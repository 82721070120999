import { createTheme } from '@mui/material/styles'
import { Colors } from './components/constants/Colors'

/**
 * theme.js - Custom Material-UI theme configuration.
 *
 * This file defines the Material-UI theme used across the app, including custom color palettes,
 * typography, and component overrides. It uses the Material-UI `createTheme` function to create
 * a consistent look and feel throughout the app by defining global styles.
 *
 * The theme is structured into the following main sections:
 *
 * 1. **Palette**: Defines the primary and secondary color schemes for the app.
 *    - Primary color: Defined as `Colors.Primary`.
 *    - Secondary color: Defined as `Colors.Secondary`.
 *
 * 2. **Typography**: Configures global font styles for all text elements.
 *    - Font family: Sets a default font stack for all text, prioritizing system fonts for better
 *      performance and compatibility.
 *    - Header styles (h5): Adds specific color settings for headers (e.g., `h5`: `Colors.Neutral`).
 *    - Body styles (body1): Adjusts the default font size for body text to `14px`.
 *
 * 3. **Component Overrides**: Customizes the appearance and behavior of Material-UI components.
 *    - **MuiCssBaseline**: Applies a global `box-sizing` rule to all elements, ensuring consistent
 *      layout behavior across the app.
 *    - **MuiLink**: Customizes link styling, removing underlines by default and adding hover effect
 *    - **MuiButton**: Defines the default size and font weight for buttons, setting a smaller font
 *      size for button text.
 *    - **MuiIconButton**: Modifies icon buttons by reducing padding and icon size, and applying
 *      consistent border-radius for visual alignment.
 *
 * 4. **Typography and Component Style Overrides**: Adds custom styles for text, links, buttons,
 *    and icon buttons to enhance visual consistency.
 *
 * This theme is provided globally using Material-UI's `ThemeProvider` in the `App` component,
 * ensuring that all components across the app adhere to these design guidelines.
 *
 * Example Usage:
 * The theme is imported into the `App.js` file and passed to `ThemeProvider` to apply the custom
 * styles across the entire app.
 *
 * Example:
 * import theme from './theme';
 *
 * <ThemeProvider theme={theme}>
 *   <App />
 * </ThemeProvider>
 *
 * @returns {Object} The custom Material-UI theme object to be used throughout the app.
 */
const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Primary
    },
    secondary: {
      main: Colors.Secondary
    }
  },
  typography: {
    allVariants: {
      // eslint-disable-next-line max-len
      // fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
      letterSpacing: '',
      color: Colors.NeutralText
    },
    h5: {
      color: Colors.NeutralText
    },
    body1: {
      fontSize: '14px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *, *::before, *::after {
          box-sizing: border-box;
        }
      `
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'primary',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.85rem',
          fontWeight: 'normal'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          padding: '16px',
          color: 'primary',
          '& .MuiSvgIcon-root': {
            fontSize: 'medium'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'primary',
          color: 'primary'
        }
      }
    }
  }
})

export default theme
