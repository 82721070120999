import React, { useState, useCallback } from 'react'
import { Box, Skeleton } from '@mui/material'
import { IMAGE_WIDTH_PIXEL, MOBILE_IMAGE_WIDTH_PIXEL, IMAGE_ASPECT_RATIO } from './SegmentDetailsConstants'
import { useGetImageQuery } from '../../api/apiSlice'
import ControlButton from '../ControlButton'
import { Views } from '../constants/Views'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

/**
 * Details of segment-related images shown in the details box.
 */
const ImageDetails = ({ mobileView, featureId, view, properties }) => {
  const [imageIndex, setImageIndex] = useState(0)
  const imagesAttached = properties !== null && properties.images !== undefined
  const imageId = imagesAttached ? properties.images[imageIndex] : null
  const { data: imageData, isLoading } = useGetImageQuery(imageId, {
    skip: !imagesAttached || view !== Views.Infrastructure
  })

  const imageWidth = mobileView ? MOBILE_IMAGE_WIDTH_PIXEL : IMAGE_WIDTH_PIXEL
  const incrementImageIndex = useCallback(() => { setImageIndex(index => index + 1) }, [])
  const decrementImageIndex = useCallback(() => { setImageIndex(index => index - 1) }, [])

  return isLoading
    ? <ImageContainer mobileView={mobileView}>
        <Skeleton
          sx={{ marginTop: '-30px' }}
          variant="rectangular"
          width={imageWidth}
          height={imageWidth * IMAGE_ASPECT_RATIO }
        />
        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
            width: (IMAGE_WIDTH_PIXEL - (mobileView ? 2 * 20 : 2 * 5)) + 'px'
          }}
        >
          <Skeleton variant="rectangular" width="50px" height="35px" sx={{ marginRight: '5px' }} />
          <Skeleton variant="rectangular" width="50px" height="35px" />
        </Box>
      </ImageContainer>
    : imagesAttached
      ? (
      <ImageContainer mobileView={mobileView}>
        <Box>
          <img
            src={featureId === null ? '' : imageData}
            alt="Segment Foto"
            style={{ marginTop: '-30px' }}
            width={imageWidth}
            height={imageWidth * IMAGE_ASPECT_RATIO}
          />
        </Box>
        <Box
          sx={{
            marginTop: '5px',
            display: 'flex',
            justifyContent: 'center',
            width: (IMAGE_WIDTH_PIXEL - (mobileView ? 2 * 20 : 2 * 5)) + 'px'
          }}
        >
          <ControlButton
            id="segmentDetailsPreviousImageButton"
            icon="navigate_before"
            active={imageIndex === 0}
            disabled={imageIndex === 0}
            width='50px'
            onClick={decrementImageIndex}
          />
          <ControlButton
            id="segmentDetailsNextImageButton"
            icon="navigate_next"
            disabled={imageIndex === properties.images.length - 1}
            active={imageIndex === properties.images.length - 1}
            width='50px'
            onClick={incrementImageIndex}
          />
        </Box>
      </ImageContainer>
        )
      : ''
}

ImageDetails.propTypes = {
  mobileView: PropTypes.bool,
  properties: PropTypes.object,
  view: PropTypes.string,
  featureId: PropTypes.number
}

const ImageContainer = styled('div')(({ mobileView }) => ({
  display: 'inline-block',
  maxHeight: '200px',
  maxWidth: `${IMAGE_WIDTH_PIXEL}px`,
  margin: mobileView ? '20px 15px 15px -5px' : '20px 20px 15px 20px',
  float: mobileView ? 'right' : 'none'
}))

export default ImageDetails
