import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { iconMap } from '../ControlButton'

/**
 * A small '(?)' icon which can be used to show a quick-help tooltip e.g. next to a text.
 *
 * To show a tooltip for a button just use the `tooltip` parameter of the `ControlButton` component.
 */
const GenericIcon = ({
  tooltip, position, right, color, icon, left, top, bottom,
  transform, margin, float, padding
}) => {
  const IconComponent = iconMap[icon]

  return (
    <Tooltip title={tooltip} enterDelay={200}>
      <StyledIconButton
        position={position}
        right={right}
        color={color}
        transform={transform}
        left={left}
        top={top}
        bottom={bottom}
        float={float}
        margin={margin}
        padding={padding}
      >
        <IconComponent />
      </StyledIconButton>
    </Tooltip>
  )
}

GenericIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  position: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  transform: PropTypes.string,
  margin: PropTypes.string,
  float: PropTypes.string,
  padding: PropTypes.string
}

const StyledIconButton = styled(IconButton)(
  ({ position, right, color, transform, left, top, bottom, float, margin, padding }) => ({
    padding: padding !== undefined ? padding : '0px',
    margin: margin !== undefined ? margin : '0px',
    backgroundColor: 'transparent',
    color: color !== undefined ? color : '#c1c1c1',
    transform: transform !== undefined ? transform : 'scale(1.0)',
    position: position !== undefined ? position : 'static',
    right: right !== undefined ? right : 'auto',
    left: left !== undefined ? left : 'auto',
    top: top !== undefined ? top : 'auto',
    bottom: bottom !== undefined ? bottom : 'auto',
    float: float !== undefined ? float : 'none'
  }))

export default GenericIcon
