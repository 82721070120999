import React, { lazy, Suspense, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs, Tab, Box, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import GenericIcon from '../sidebar/GenericIcon'
import { defaultErrorHandling } from '../ErrorHandlingHelpers'
import { surfaceType, highwayType } from './OsmHelper'
import { Views } from '../constants/Views'
import {
  IMAGE_WIDTH_PIXEL, MOBILE_IMAGE_WIDTH_PIXEL, SMALL_MOBILE_IMAGE_WIDTH_PIXEL,
  Categories, streetDetailsName, streetConditionName
} from './SegmentDetailsConstants'
import { useGetSegmentHistoryQuery } from '../../api/apiSlice'
import { Modes } from '../sidebar/datasets/DatasetsAnalysis'
import ImageDetails from './ImageDetails'
import { Sources } from '../constants/Sources'

// Lazy import PlotlyChart to speed-up initial loading of the page.
const Chart = lazy(() => import('./PlotlyChart'))

/**
 * Details of a segment shown in the sidebar when a segment is selected.
 *
 * @author Armin Schnabel
 */
const SegmentDetails = ({ map, logout, mobileView }) => {
  // Redux state
  const featureId = useSelector((state) => state.infrastructureView.clickedWayId)
  const view = useSelector((state) => state.ui.view)
  const mode = useSelector((state) => state.infrastructureView.mode)

  // Local state
  const [activeTab, setActiveTab] = useState(streetDetailsName)

  const properties = featureProperties(map, featureId)
  const { data: history, error, isLoading, isError } = useGetSegmentHistoryQuery(properties, {
  // When the feature set is reloaded by the app, it can happen, that the clickedWayId
  // does not exist anymore, so we catch this softly. [DAT-1491]
    skip: properties === null || view !== Views.Infrastructure
  })

  if (isError) {
    defaultErrorHandling(error, logout)
  }

  const onTabClick = (event, newValue) => {
    setActiveTab(newValue)
  }

  /**
   * Shows details about the selected (or hovered) Segment.
   */
  const segmentDetails = (featureId, properties) => {
    if (featureId == null) {
      return '' // no segment selected
    }
    return (
      <DetailsContainer>
        <InfoContainer mobileView={mobileView}>
          <label>Ausgewähltes Straßensegment:</label>
          <Content>
            <div>
              Name: { properties.tags.name }
              <br />
              Identifikatoren: Weg{' '}
              <a
                href={`https://www.openstreetmap.org/way/${properties.way}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {properties.way}
              </a>
              &nbsp;vNK{' '}
              <a
                href={`https://www.openstreetmap.org/node/${properties.vnk}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {properties.vnk}
              </a>
              &nbsp;nNK&nbsp;
              <a
                href={`https://www.openstreetmap.org/node/${properties.nnk}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {properties.nnk}
              </a>
              <GenericIcon
                icon="help_outline"
                transform="scale(0.8)"
                tooltip={'Von-Netzknoten-ID (vNK) - Nach-Netzknoten-ID (nNK), das entspricht der' +
                ' `Orientierungsrichtung` in die der Weg definiert ist'}
              />
              <br />
              Abschnitt: { Math.round(properties.way_offset) } -&nbsp;
              {Math.round(Number(properties.way_offset) + Number(properties.length)) }m, &nbsp;
              {(properties.forward_moving ? 'in ' : 'gegen') } Orientierungsrichtung
              <GenericIcon
                icon="help_outline"
                transform="scale(0.8)"
                tooltip={'`In Orientierungsrichtung` bedeutet die Fahrtrichtung während der ' +
                'Aufzeichnung entspricht der Richtung Von-Netzknoten -> Nach-Netzknoten.'}
              />
              <br />
              Typ: {/* Straßenklasse: */ highwayType(properties.tags.highway)},&nbsp;
              {/* Baustoff: */ surfaceType(properties.tags.surface) }
              <GenericIcon
                icon="help_outline"
                transform="scale(0.8)"
                tooltip="Laut OpenStreetMap"
              />
              <br />
              Oberflächenqualität:{' '}
              {properties.quality === 0
                ? 'Rau'
                : properties.quality === 1
                  ? 'Mäßig'
                  : properties.quality === 2
                    ? 'Normal'
                    : properties.quality === 3 ? 'Glatt' : 'Zuordnungsfehler' }
              <br />
              <Grey>Support-ID: {properties.oid}</Grey>
              <br />
            </div>
          </Content>
        </InfoContainer>

      {// Images are not shown on small screens
      }
       {window.innerWidth > SMALL_MOBILE_IMAGE_WIDTH_PIXEL
         ? <ImageDetails
          mobileView={mobileView}
          featureId={featureId}
          view={view}
          properties={properties}
        />
         : '' }
      </DetailsContainer>
    )
  }

  const tabsContainer = (featureId, properties, history, view) => {
    if (history === null || view !== Views.Infrastructure || mode !== Modes.Segment) {
      return ''
    }
    return (history.features.length !== 0
      ? <TabsContainer>
          <Tabs value={activeTab} onChange={onTabClick} variant="fullWidth">
            <Tab value={streetDetailsName} label={streetDetailsName} />
            <Tab value={streetConditionName} label={streetConditionName} />
          </Tabs>

          { activeTab === streetDetailsName && segmentDetails(featureId, properties) }
          { activeTab === streetConditionName && chart(featureId, history) }
      </TabsContainer>
      : <TabsContainer>
          <Tabs value={activeTab} variant="fullWidth">
            <Tab value={streetDetailsName} label={streetDetailsName} />
          </Tabs>

          { segmentDetails(featureId, properties)}
      </TabsContainer>
    )
  }

  const renderSkeleton = () => <Skeleton height={IMAGE_WIDTH_PIXEL - 50} />

  /**
   * Shows a container with the historical segment conditions.
   *
   * @param {*} featureId TODO
   * @param {*} history TODO
   */
  const chart = (featureId, history) => {
    return (
      <Box sx={{ padding: '10px 0px 0px 0px' }}>
        <Suspense fallback={renderSkeleton()}>
          <Chart data={chartData(featureId, history)} />
        </Suspense>
      </Box>
    )
  }

  return properties !== null
    ? (isLoading
        ? renderSkeleton()
        : tabsContainer(featureId, properties, history, view)
      )
    : ''
}

/**
 * Returns the data for a selected feature which is required to fill the chart.
 *
 * @param {*} featureId TODO
 * @param {*} history TODO
 */
const chartData = (featureId, history) => {
  if (featureId == null) {
    return [] // no segment selected
  }
  const x = []
  const y = []
  history.features.forEach(f => {
    const properties = f.properties
    const quality = properties.quality
    const value =
      quality >= 2.5
        ? Categories.SMOOTH // 2.5 - 3.0
        : quality >= 1.5
          ? Categories.NORMAL
          : quality >= 0.5
            ? Categories.MODERATE
            : Categories.ROUGH // 0.0 - 0.5
    const date = new Date(properties.timestamp * 1000)
    x.push(date.toISOString())
    y.push(value)
  })
  return [
    {
      x,
      y,
      type: 'scatter'
      // this only sets static color for the first, second, etc. marker (wrong)
      // marker: { color: [
      //  ClassificationColors.RED,
      //  ClassificationColors.YELLOW,
      //  ClassificationColors.GREEN,
      //  ClassificationColors.BLUE
      // ] }
    }
  ]
}

/**
  * Loads the properties of a feature.
  *
  * @param {object} map to load the feature from
  * @param {object} featureId id of the feature to show properties for
  * @return the properties of the feature or null if no feature is selected, or when the feature
  * cannot be found anymore (e.g. when the dataset is reloaded from the API).
  */
const featureProperties = (map, featureId) => {
  if (featureId === null) {
    return null
  }
  const mapSource = map.getSource(Sources.Segment)
  const mapFeatures = mapSource._data.features
  const matchedFeatures = mapFeatures.filter(f => f.id === featureId)
  return matchedFeatures.length === 1 ? matchedFeatures[0].properties : null
}

const TabsContainer = styled('div')({
  minWidth: '350px',
  padding: '0'
})

const DetailsContainer = styled('div')({
  width: '100%',
  padding: '20px 0 10px 0'
})

const InfoContainer = styled('div')(({ mobileView }) => ({
  verticalAlign: 'top',
  width: mobileView
    ? `calc(100% - ${MOBILE_IMAGE_WIDTH_PIXEL}px - 15px)`
    : `calc(100% - ${IMAGE_WIDTH_PIXEL}px - 50px)`,
  display: 'inline-block',
  padding: '0px 5px 0px 20px'
}))

const Content = styled('div')({
  padding: '5px 0px 0px 10px',
  lineHeight: 2
})

const Grey = styled('span')({
  color: 'grey'
})

export default SegmentDetails
