import React, { createContext, useContext, useState, useEffect } from 'react'
import { Snackbar } from '@mui/material'
import PropTypes from 'prop-types'
import { setSnackbarHandler } from './SnackbarService'

const SnackbarContext = createContext()

export const useSnackbarContext = () => useContext(SnackbarContext)

export const SnackbarProvider = ({ children }) => {
  const [snackPack, setSnackPack] = useState([])
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState(undefined)

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] })
      setSnackPack((prev) => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false)
      setMessageInfo(null)
    }
  }, [snackPack, messageInfo, open])

  useEffect(() => {
    setSnackbarHandler(enqueueSnackbar)
  }, [])

  const enqueueSnackbar = (message, options = {}) => {
    // console.log(message)
    setSnackPack((prev) => [...prev, { message, ...options }])
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setMessageInfo(null)
  }

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        // e.g. DD simulation takes 3 minutes until 1% is shown
        autoHideDuration={messageInfo ? messageInfo.autoHideDuration : 180_000}
        onClose={closeSnackbar}
        message={messageInfo ? messageInfo.message : undefined}
        action={messageInfo ? messageInfo.action : undefined}
      />
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.any
}
