import { updateDatasetsView } from '../../../reducers/datasetsView'
import normalize from '@mapbox/geojson-normalize'
import { calculateBoundingBox } from './DatasetUtils'
import { Sources } from '../../constants/Sources'
import { getGeoLocations } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { showSnackbar } from '../../SnackbarService.js'

/**
   * Shows a specified dataset on the map.
   *
   * @param {*} dataset The dataset to show.
   */
export const showDatasetOnMap = async (
  dataset,
  logout,
  map,
  dispatch,
  visibleLayerId,
  showDatasetWithDashedLines
) => {
  if (!dataset.deserialized) {
    showDatasetWithDashedLines(dataset)
  } else {
    const currentId = dataset.id.toString()

    const paddingBottom = 25 + 275 + 30 // 25 track padding, details box size + box space bottom

    dispatch(updateDatasetsView({ datasetLoading: true }))

    // cancel any previous requests
    const locations = await getGeoLocations(dispatch, defaultErrorHandling, logout, dataset)

    if (!locations) {
      return
    }

    // If the dataset is already old(another dataset is selected already) and removed.
    /* if (!selected.some(id => id === dataset.id)) {
        dispatch(updateDatasetsView({ datasetLoading: false }))
        return
      } */

    if (locations.features.length !== 1) {
      showSnackbar('Messung enthält keinen Track!')
      showDatasetWithDashedLines(dataset)
      dispatch(updateDatasetsView({ datasetLoading: false }))
      return
    }

    const normalizedData = normalize(locations)
    const coordinates = normalizedData.features[0].geometry.coordinates
    const coordinatesArray = [].concat.apply([], coordinates)

    const bounds = calculateBoundingBox(coordinatesArray)

    if (!map.getSource(currentId)) {
      map.addSource(currentId, {
        type: 'geojson',
        data: normalizedData
      })
    }

    if (!map.getLayer(currentId)) {
      map.addLayer({
        id: currentId,
        type: 'line',
        source: currentId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-width': 3,
          'line-color': '#3F8730'
        }
      })
    }

    // showOnMap is also called when switching away from dataset view.
    // i.e. check if we are still in SHOW_DATASETS view, if not hide.
    if (visibleLayerId === Sources.Datasets) {
      map.fitBounds(bounds,
        { padding: { top: 25, bottom: paddingBottom, left: 25, right: 25 }, maxZoom: 16 })
      map.setLayoutProperty(currentId, 'visibility', 'visible')
    } else {
      map.setLayoutProperty(currentId, 'visibility', 'none')
    }

    dispatch(updateDatasetsView({ datasetLoading: false }))
  }
}
