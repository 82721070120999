import { createSlice } from '@reduxjs/toolkit'

/**
 * Reducer code specific to the `segments` data.
 *
 * @author Armin Schnabel
 */

/**
 * The GeoJson features returned by the `segments` endpoint.
 *
 * The data is not yet stored in the normalized `byId` format as we currently don't
 * reference the data from another data type.
 */

const initialState = {
  data: [],
  hasSegments: false
}

/**
 * Handler for an entire slices of a state ("slice reducers")
 *
 * Sets the initial state & connects the actions with the reducer functions.
 */
const segmentsSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    setSegments: (state, action) => {
      state.data = action.payload
      state.hasSegments = action.payload.length > 0
    }
  }
})

/**
 * Handlers for specific cases ("case reducers")
 *
 * Attention!
 * - Never alter the original copy of the state or else re-rendering won't work
 */

export const { setSegments } = segmentsSlice.actions

export const selectHasSegments = (state) => state.segments.hasSegments

export const segmentsReducer = segmentsSlice.reducer
export { initialState as segmentsInitialState }
