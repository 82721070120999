import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Modal, Box, Grid } from '@mui/material'
import DatePicker from './DatePicker'
import { useSnackbarContext } from '../SnackbarContext.js'
import ControlButton from '../ControlButton.js'

/**
 * Allows to define a time-based filter.
 */
const TimeFilter = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  time,
  setTime,
  disabled
}) => {
  // Stateless Hooks
  const { enqueueSnackbar } = useSnackbarContext()

  // Local state to control modal visibility
  const [isModalOpen, setModalOpen] = useState(false)

  /**
   * Generates the name for the datepicker triggering button.
   *
   * @returns The generated name
   */
  const currentTriggerText = () => {
    const { from, to } = time

    /* Check if both of them are not provided */
    const fromDateUnspecified = from === null
    const toDateUnspecified = to === null

    const toDateString = new Date(to).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })

    /* Offset the substracted 1 millisecond. */
    const fromDateString = new Date(from + 1).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
    if (!fromDateUnspecified && !toDateUnspecified) {
      return fromDateString + ' - ' + toDateString
    } else if (!fromDateUnspecified && toDateUnspecified) {
      return 'ab ' + fromDateString
    } else if (fromDateUnspecified && !toDateUnspecified) {
      return 'bis ' + toDateString
    } else {
      return 'Zeitraum'
    }
  }

  const text = currentTriggerText()
  const trigger = () => {
    return <ControlButton
      disabled={disabled}
      text={text}
      active={text !== 'Zeitraum'}
      onClick={() => setModalOpen(true)}
    />
  }

  /**
   * Updates the to-date, e.g. when the user selects a new date.
   *
   * @param toDate the user-selected starting date
   */
  const handleToDateChanged = (toDate) => {
    const lastSecondOfTheDay = new Date(toDate.setDate(toDate.getDate() + 1) - 1000)
    setToDate(lastSecondOfTheDay)
  }

  /**
   * Persists the newly selected time frame.
   *
   * @param e the triggered event from this handler.
   */
  const onAccept = (e) => {
    /* Check if both of them are not provided */
    const fromDateUnspecified = fromDate === null
    const toDateUnspecified = toDate === null

    if (fromDateUnspecified && toDateUnspecified) {
      enqueueSnackbar('Bitte wählen Sie einen Erfassungszeitraum aus.')
      return
    }

    /* Set time for the one that is provided. */
    const newFrom = fromDateUnspecified ? null : fromDate.getTime()
    const newTo = toDateUnspecified ? null : toDate.getTime()
    setTime({
      from: newFrom,
      to: newTo,
      active: true
    })

    // Close the modal after accepting the time
    setModalOpen(false)
  }

  return (
    <>
      {trigger()}

      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <ModalBox>
          <TimeframeDefinition>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  label="von"
                  onChange={setFromDate}
                  defaultDate={fromDate}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="bis"
                  onChange={handleToDateChanged}
                  defaultDate={toDate}
                />
              </Grid>
            </Grid>
          </TimeframeDefinition>
          <ButtonContainer>
            <ControlButton
              text="Zeitraum festlegen"
              onClick={onAccept}
              active={false}
              height='40px'
              sx={{ marginRight: '10px' }}
            />
            <ControlButton
              text="Abbrechen"
              onClick={() => setModalOpen(false)}
              active={false}
              height='40px'
            />
          </ButtonContainer>
        </ModalBox>
      </Modal>

    </>
  )
}

TimeFilter.propTypes = {
  fromDate: PropTypes.object, // can be null
  toDate: PropTypes.object, // can be null
  setFromDate: PropTypes.func.isRequired,
  setToDate: PropTypes.func.isRequired,
  time: PropTypes.object.isRequired,
  setTime: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const ModalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  backgroundColor: 'white', // Ensures white background for the modal content
  borderRadius: '8px',
  boxShadow: '24px',
  padding: '16px',
  outline: 0
})

const TimeframeDefinition = styled('div')({})

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1rem'
})

export default TimeFilter
