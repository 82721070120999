/**
 * Calculate the bounding box of a set of coordinates.
 *
 * @param {*} coordinatesArray The array of coordinates to calculate the bounding box from.
 * @returns The bounding box in the form [[minLng, minLat], [maxLng, maxLat]].
 */
export const calculateBoundingBox = (coordinatesArray) => {
  return coordinatesArray.reduce((bounds, [lng, lat]) => {
    const [minLng, minLat] = bounds[0]
    const [maxLng, maxLat] = bounds[1]
    return [
      [Math.min(minLng, lng), Math.min(minLat, lat)],
      [Math.max(maxLng, lng), Math.max(maxLat, lat)]
    ]
  }, /* init value = */ [
    [coordinatesArray[0][0], coordinatesArray[0][1]],
    [coordinatesArray[0][0], coordinatesArray[0][1]]
  ])
}

/**
 * Filters the datasets based on a combined filter (e.g. modality + time).
 *
 * @param {*} datasets The datasets to filter.
 * @param {*} combinedFilter The combined filter to apply.
 * @returns The filtered datasets.
 */
export const filterDatasets = (datasets, combinedFilter) => {
  return datasets.filter(combinedFilter)
}
