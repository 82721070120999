import { useState, useEffect } from 'react'
import { fetchConfig } from '../config/config.js'

/**
 * useConfig - A custom hook to load the static configuration for the app.
 *
 * This hook is responsible for fetching the configuration (e.g., from a static file
 * or environment variables) when the app is initialized. The configuration is only
 * loaded once on the initial render and never changes afterward.
 *
 * The configuration is expected to contain critical app settings such as:
 * - REACT_APP_DefaultLocation: The default geographical location to be used in the app.
 * - REACT_APP_Style: The UI style or theme to be applied.
 *
 * @returns {Object|null} config - The configuration object when loaded, or null if still loading.
 * The expected structure of the returned config object is:
 * {
 *   REACT_APP_DefaultLocation: string, // e.g., "[0, 0]" (a stringified array)
 *   REACT_APP_Style: string // e.g., "RFR" (a constant representing the style)
 * }
 *
 * @throws Will throw an error if fetching the configuration fails.
 *
 * Example usage:
 * const config = useConfig();
 * if (!config) return <div>Loading...</div>;
 * console.log(config.REACT_APP_DefaultLocation); // Access the config values
 *
 * This hook ensures that the app doesn't render until the configuration is fully loaded.
 */
const useConfig = () => {
  const [config, setConfig] = useState(null)

  useEffect(() => {
    fetchConfig()
      .then(setConfig)
      .catch(error => { throw new Error('Error loading config: ' + error.message) })
  }, [])

  return config
}

export default useConfig
