// We use the #-Format to define colors (common and easy to identify).
// Convert the #-Format to rgb() where needed with the converters see RadSim app.

const cyfaceGreen = '#3f8730' // dark green - primary Cyface color
const lightGreen = '#579029' // light green from Cyface logo
const lightBlack = '#3C3C3C'

/**
 * Colors used by the app UI.
 */
export const Colors = {
  Primary: cyfaceGreen,
  Secondary: lightGreen,
  Neutral: 'rgb(153, 153, 153)',
  NeutralText: lightBlack
}

/**
 * Colors for classified points or segments.
 */
export const ClassificationColors = {
  BLUE: '#0055ff',
  GREEN: '#00ff59', // 4 categories
  // const BLUE_GREEN = '#00AA55' // 5 categories
  // const YELLOW_GREEN = '#90CC0C' // 5 categories
  YELLOW: '#FFDC00',
  RED: '#ff2600'
}
