import {
  AuthorizationServiceConfiguration, FetchRequestor,
  TokenRequest, BaseTokenRequestHandler, BasicQueryStringUtils, GRANT_TYPE_REFRESH_TOKEN
} from '@openid/appauth'
import { LocalStorage } from '../constants/LocalStorage'
import { defaultErrorHandling } from '../ErrorHandlingHelpers'
import { getUser } from '../DataApi'

/**
 * The utils.js file contains javascript code used by multiple html files.
 *
 * @author Armin Schnabel
 */

/**
 * Helps to ensure the local storage is not overwritten when multiple instances of our
 * app run under the same domain.
 */
export const localStorageSuffix = ''

/**
 * @returns Email address to show to the user to get support.
 */
export function supportEmail () {
  return 'support@cyface.de'
}

/**
 * @returns `True` if the current connection is secure. This means that all other connections
 * also need to be secure, e.g. WebSocket.
 */
export function isHttps () {
  return window.location.protocol === 'https:'
}

/**
 * @returns `True` if running against a production setup.
 */
export function isProductionEnvironment () {
  return process.env.REACT_APP_ENVIRONMENT === 'production'
}

/**
 * @returns `True` if running against a provider setup.
 */
export function isProviderEnvironment () {
  return process.env.REACT_APP_ENVIRONMENT === 'provider'
}

/**
 * Copies in `src/setupProxy.js` and `src/index.js` (near `makeServer`).
 *
 * @returns `True` if running against a mock-API.
 */
export function isMockEnvironment () {
  const environment = process.env.REACT_APP_ENVIRONMENT
  // Mirage has two environments : test and development. The test environment
  // turns off Mirage's artificial latency, ignores any initial seeds(), and disables logging
  // to the console. In development mode, the Mirage server will have a default response time
  // of 400ms, logs all server responses to the console, and loads the development seeds.
  return environment === 'development' || environment === 'test'
}

/**
 * The path to the API.
 *
 * To access an API from another domain in dev-environment see the Readme-section for how
 * to avoid CORS-errors in the browser.
 *
 * @returns the (relative) path
 */
export function getApiUrl () {
  const apiEndpoint = 'api/v2/'
  // `production.nginx` forwards `/provider/*` to the provider port (8086) to avoid CORS issues
  const proxiedApiEndpoint = 'provider/' + apiEndpoint
  return isProductionEnvironment() ? proxiedApiEndpoint : apiEndpoint
}

/**
 * The path to the websocket API.
 *
 * As package.json `proxy` is ignored for WebSockets (in Chrome), we're using a workaround
 * defined in `src/setupProxy.js`. (https://github.com/facebook/create-react-app/issues/5280)
 *
 * When testing locally, we bypass the proxy use use unsecure http/ws schema.
 *
 * We can't pass a relative path to the Websocket component.
 *
 * @returns the (relative) path
 */
export function getWebsocketApiUrl () {
  const scheme = isHttps() ? 'wss://' : 'ws://'
  const defaultHost = window.location.host // like `localhost:3000` or `app.cyface.de`
  // for some reasons `localhost:3000` does not work with `provider` setup
  const devHost = 'localhost:8086'
  const host = isProviderEnvironment() ? devHost : defaultHost
  return scheme + host + '/' + getApiUrl()
}

/**
 * @returns `True` if debug messages should be logged to the console.
 */
export function debug () {
  return !isProductionEnvironment()
}

/**
 * `true` if the authentication should also be mocked in the `provider` environment.
 *
 * This only works when the provider was started with `auth-type: mocked`.
 * To login as a specific user, start the provider with `oauth.userId="SOME_UUID"`, or
 * else the provider defaults to the user id `00000000-0000-0000-0000-000000000000`.
 */
export const forceMockAuth = process.env.REACT_APP_AUTH_FORCE_MOCK

/**
 * `true` if the authentication API should be mocked.
 *
 * This is the default choise in the `development/test` environment.
 * In the `provider` environment this defaults to `false` but can be overwritten
 * with `forceMockAuth`.
 *
 * @see forceMockAuth for details.
 */
export function mockAuth () {
  return isMockEnvironment() || forceMockAuth === 'true'
}

/**
 * Stores values to the `localStorage`.
 *
 * In order to handle other types than `String`s the `value` is `JSON.stringify()`ed.
 * The reason for this is that `localStorage` only supports strings.
 *
 * @param key
 * @param value
 */
export function setLocalStorage (key, value) {
  window.localStorage.setItem(key + localStorageSuffix, JSON.stringify(value))
}

/**
 * Retrieves values from the `localStorage`.
 *
 * See `setLocalStorage()` for more details.
 *
 * @param key
 */
export function getLocalStorage (key) {
  if (window.localStorage.getItem(key + localStorageSuffix) === null) {
    // Nothing to do
    // This happens regularily when we check if there is a token stored for a user who is
    // not signed in
  }
  return JSON.parse(window.localStorage.getItem(key + localStorageSuffix))
}

export function termsAccepted () {
  return getLocalStorage(LocalStorage.TermsAccepted)
}

export function errorTrackingAccepted () {
  return getLocalStorage(LocalStorage.ErrorTrackingAccepted)
}

/**
 * Checks if the user is logged in (i.e. a refresh token exists).
 *
 * This does not check if the token is still valid, only if a token exists.
 * Use `autoLogin()` to check if the `refreshToken` can still produce an `accessToken`.
 *
 * @returns `true` if the user is logged in
 */
export function loggedIn () {
  const refreshToken = getLocalStorage(LocalStorage.RefreshToken)
  return typeof refreshToken !== 'undefined' && refreshToken != null
}

/**
 * Checks the stored auth token and forwards the user.
 *
 * This is a async but blocking function which waits for the login to succeed.
 *
 * @param {*} navigate The React hook function `navigate`
 * @param {*} successPath The path to navigate the user to when login was successful
 * @param {*} failurePath The path to navigate the user to when login failed
 * @return `true` if login was successful or `false` otherwise
 */
export async function autoLogin (navigate, successPath, failurePath, dispatch, logout) {
  if (!loggedIn() || !termsAccepted()) {
    if (debug()) {
      console.log('Auto-login failed: ' + (!loggedIn() ? 'no refresh token' : 'terms not accepted'))
    }
    localStorage.clear()
    navigate(failurePath)
    return false
  }

  // await asyncRefreshTokenIfRequired() - should now be done by apiSlice

  // Synchronous call to wait for the result before executing the remaining code
  const username = getLocalStorage(LocalStorage.Username)
  try {
    await getUser(dispatch, defaultErrorHandling, logout, username)
    navigate(successPath)
    return true
  } catch (error) {
    if (debug()) {
      console.log('Auto-login failed: ' + error)
    }
    localStorage.clear()
    navigate(failurePath)
    return false
  }
}

/**
 * Tells BasicQueryStringUtils to not use hashes for routing.
 */
export class NoHashQueryStringUtils extends BasicQueryStringUtils {
  parse (input, useHash) {
    return super.parse(input, false)
  }
}

/**
 * Login endpoint configuration.
 */
export function getConfig () {
  return {
    authRealmUrl: process.env.REACT_APP_AUTH_REALM_URL,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    redirectUri: window.location.origin + '/callback'
  }
}

/**
 * Stores the access- and refresh token together with the access token expiry time.
 *
 * @param {*} tokenResponse The response from the token request.
 */
export function storeTokens (tokenResponse) {
  const expiresAt = new Date().getTime() + tokenResponse.expiresIn * 1000
  setLocalStorage(LocalStorage.AccessToken, tokenResponse.accessToken)
  setLocalStorage(LocalStorage.RefreshToken, tokenResponse.refreshToken)
  setLocalStorage(LocalStorage.ExpiresAt, expiresAt)
}

/**
 * Checks if the accessToken is valid less than 30 seconds and refreshes the token if so.
 *
 * The refreshed token is stored in the `LocalStorage`.
 */
export async function asyncRefreshTokenIfRequired () {
  const expiresAt = getLocalStorage(LocalStorage.ExpiresAt)
  const refreshRequired = new Date().getTime() >= expiresAt - 30_000
  if (refreshRequired) {
    const refreshToken = getLocalStorage(LocalStorage.RefreshToken)
    await asyncRefreshRequest(refreshToken)
  }
}

export const ERROR_MESSAGE_TOKEN_INACTIVE = 'Token refresh failed, maybe the token is inactive?'

/**
 * Internal function which sends the actual refresh token request and stores the new tokens.
 *
 * @param {*} refreshToken The refresh token required to refresh the access token.
 */
async function asyncRefreshRequest (refreshToken) {
  if (mockAuth()) {
    console.log('Mocking authentication ...')
    storeTokens({
      expiresIn: (new Date().getTime() / 1000.0) + 3600,
      accessToken: 'eyMockAccessToken',
      refreshToken: 'eyMockRefreshToken'
    })
    return
  }

  const config = getConfig()
  const request = new TokenRequest({
    client_id: config.clientId,
    redirect_uri: config.redirectUri,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    code: undefined,
    refresh_token: refreshToken,
    extras: undefined
  })

  const authServiceConfiguration = getLocalStorage(LocalStorage.AuthServiceConfiguration)
  const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())

  try {
    const response = await tokenHandler.performTokenRequest(authServiceConfiguration, request)
    storeTokens(response)
  } catch (error) {
    // This happens when the `token` request responds with 400 and body:
    // `{"error":"invalid_grant","error_description":"Token is not active"}`)
    // Unfortunately we cannot get the body here, so we always assume the token is invalid.
    throw new Error(ERROR_MESSAGE_TOKEN_INACTIVE)
  }
}

export async function asyncFetchOAuthConfiguration () {
  const config = getConfig()
  const response = await AuthorizationServiceConfiguration
    .fetchFromIssuer(config.authRealmUrl, new FetchRequestor())

  // Store configuration returned by discovery
  setLocalStorage(LocalStorage.AuthServiceConfiguration, response)

  return response
}

export function authHeader () {
  return { Authorization: 'Bearer ' + getLocalStorage(LocalStorage.AccessToken) }
}
