import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@mui/material'
import TimeFilter from '../TimeFilter.js'
import ControlButton from '../../ControlButton.js'
import { modalityToGerman, Modalities } from '../Modality'
import { setActiveTags, updateDatasetsView } from '../../../reducers/datasetsView.js'
import { useDispatch } from 'react-redux'

/**
 * Displays the filters in the DatasetsView.
 */
const DatasetsFilter = ({ modality, time, tags, disabled }) => {
  // Stateless Hooks
  const dispatch = useDispatch()

  // Local state
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const setTime = useCallback((time) => {
    dispatch(updateDatasetsView({ time }))
  }, [dispatch])

  const setModality = useCallback((modality) => {
    dispatch(updateDatasetsView({ modality }))
  }, [dispatch])

  /* const handleAddActiveTag = useCallback((tag) => {
    dispatch(addActiveTag(tag))
  }, [dispatch]) */

  /* const checkAndAddActiveTag = useCallback((tag) => {
    const { activeTags } = tags
    if (!activeTags.some(activeTag => areTagsEqual(activeTag, tag))) {
      handleAddActiveTag(tag)
    }
  }, [tags, handleAddActiveTag]) */

  /**
   * Clears the time frame.
   *
   * @param e the triggered event from this handler.
   */
  const onTimeFilterClear = useCallback(() => {
    setFromDate(null)
    setToDate(null)

    // Update global state
    setTime({
      active: false,
      to: null,
      from: null
    })
  }, [setTime, setFromDate, setToDate])

  const onClearFilters = useCallback(() => {
    setModality(Modalities.All)
    onTimeFilterClear()
    dispatch(setActiveTags([]))
  }, [setModality, onTimeFilterClear, dispatch])

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // TODO [RFR-301]: const { availableTags } = tags
  return (
    <div>
      { /* modality filter */ }
      <ControlButton
        text={modalityToGerman(modality)}
        onClick={handleMenuClick}
        disabled={disabled}
        active={modality !== Modalities.All}
        sx={{ margin: '0px 5px 5px 0px' }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {Object.values(Modalities).map((x) => (
          <MenuItem
            key={x}
            onClick={() => {
              setModality(x)
              handleMenuClose()
            }}
            disabled={modality === x}
          >
            {modalityToGerman(x)}
          </MenuItem>
        ))}
      </Menu>

      { /* tag filter
      TODO [RFR-301]: // this is not yet migrated to mui/materialize, see other Dropdowns
      <Dropdown
        options={{ constrainWidth: false }}
        trigger={
          <ControlButton
            text="Tags"
            backgroundColor="#3F8730"
            sx={{ color: '#FFFFFF', margin: '0px 5px 5px 0px' }}
            disabled={disabled}
            tooltip="Tags auswählen"
          />
        { availableTags.map(tag => {
          return (
            <a
              style={{ color: '#3F8730', fontSize: '14px' }}
              key={Object.values(tag)[0]} onClick={() => checkAndAddActiveTag(tag)} >
            {`${Object.keys(tag)[0]}: ${Object.values(tag)[0]} `}
            </a>
          )
        })
        }
      </Dropdown> */ }

      { /* time filter */ }
      <TimeFilter
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        time={time}
        setTime={setTime}
        disabled={disabled}
      />

      { /* reset filters */ }
      <ControlButton
        icon={'filter_list_off'}
        onClick={onClearFilters}
        disabled={
          disabled || (modality === Modalities.All && !time.active && tags.activeTags.length === 0)
        }
        tooltip='Filter zurücksetzen'
      />
    </div>
  )
}

DatasetsFilter.propTypes = {
  modality: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default DatasetsFilter
