import React, { useState, useCallback } from 'react'
import TimeFilter from '../TimeFilter.js'
import ControlButton from '../../ControlButton.js'
import { updateExportView } from '../../../reducers/exportView.js'
import { useDispatch, useSelector } from 'react-redux'

/**
 * Displays the filters in the ExportView.
 */
const ExportFilter = () => {
  // Stateless Hooks
  const dispatch = useDispatch()

  // Local state
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  // Redux state
  const time = useSelector((state) => state.exportView.time)

  const setTime = useCallback((time) => {
    dispatch(updateExportView({ time }))
  }, [dispatch])

  /**
   * Clears the time frame.
   */
  const onTimeFilterClear = useCallback(() => {
    setFromDate(null)
    setToDate(null)

    // Update global state
    setTime({
      active: false,
      to: null,
      from: null
    })
  }, [setTime])

  /**
   * Handles the full clear of all filters.
   */
  const onClearFilters = () => {
    onTimeFilterClear()
  }

  return (
    <div>
      <TimeFilter
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        onClickClear={onClearFilters}
        time={time}
        setTime={setTime}
        disabled={false}
      />

      <ControlButton
        icon={'filter_list_off'}
        onClick={onClearFilters}
        disabled={!time.active}
        tooltip='Filter zurücksetzen'
      />
    </div>
  )
}

ExportFilter.propTypes = {
}

export default ExportFilter
