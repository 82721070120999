/**
 * Fetch config at runtime.
 *
 * Create React App (CRA) compatible environment variables can only be injected at build time.
 */
export const fetchConfig = async () => {
  // `public/config/config.json` - set in the docker deployment in entrypoint.sh
  const response = await fetch('/config/config.json')
  if (!response.ok) {
    throw new Error('Failed to load config')
  }
  return await response.json()
}
