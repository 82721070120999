import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Views } from '../constants/Views'

/**
 * DetailsBox is a generic component to represent a box where
 * children components handles the logic to show dataset or segment details inside the box.
 */

const selectedDatasetId = (selected) => {
  return selected[0] !== undefined ? selected[0] : null
}

const DetailsBox = ({ mobileView, children }) => {
  // Redux state
  const view = useSelector((state) => state.ui.view)
  const clickedWayId = useSelector((state) => state.infrastructureView.clickedWayId)
  const selected = useSelector((state) => state.datasetsView.selected)

  const selectedId = view === Views.Infrastructure
    ? clickedWayId
    : view === Views.Datasets ? selectedDatasetId(selected) : null

  return <Container
    width={mobileView ? 'calc(100vw - 20px)' : 'calc((100vw - 400px) - 20px)'}
    right={'10px'}
    visibility={selectedId === null ? 'hidden' : 'visible'}
    opacity={selectedId === null ? '0' : '1'}
  >
    {children}
  </Container>
}

/**
 * The style of the wrapper element which is a box at the bottom of the map.
 */
const Container = styled('div')(({ width, right, visibility, opacity }) => ({
  position: 'fixed',
  bottom: '30px',
  right,
  zIndex: 4,

  width,

  transition: 'visibility 0s, opacity 0.05s linear',
  visibility,
  opacity,

  backgroundColor: '#FFFFFF',
  border: 'solid 1px lightgrey',

  padding: '0px',
  fontSize: 'small',
  '& a': {
    color: 'green !important'
  }
}))

DetailsBox.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired
}

export default DetailsBox
