import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { Modes, Pipelines } from '../components/sidebar/datasets/DatasetsAnalysis'
import { Status } from '../components/JobHelpers'

/**
 * Reducer code specific to the data returned by the `jobs` endpoint.
 *
 * The domain data is stored in the normalized shape:
 * https://redux.js.org/usage/structuring-reducers/normalizing-state-shape
 *
 * @author Armin Schnabel
 */

const jobsAdapter = createEntityAdapter()
const initialState = jobsAdapter.getInitialState({
  latestH3DeletionDate: null, // serializable ISO-String
  latestSegmentDeletionDate: null, // serializable ISO-String
  latestJobsAfterDeletionByDataset: {}, // Format: { datasetId: { modeId: jobId } }
  partiallyDeletedAnalysisByMode: {} // Stores the result per mode { mode: boolean }
})

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    addJobs: (state, action) => {
      // Accepts an array of jobs and adds or replaces them
      jobsAdapter.setMany(state, action.payload)

      // First, update deletion dates for all jobs and store the updated values
      action.payload.forEach((job) => {
        updateDeletionDate(state, job)
      })

      action.payload.forEach((job) => {
        // Check if it's the latest job after a deletion
        updateLatestJobsAfterDeletionReferences(state, job)

        // After adding jobs, update status for each mode to see if any analysis is deprecated
        updatePartiallyDeletedAnalysisByMode(state)
      })
    }
  }
})

// Helper functions

const updateDeletionDate = (state, job) => {
  if (job.pipeline === Pipelines.Delete && job.mode === Modes.Segment &&
    job.status === Status.Finished) {
    state.latestSegmentDeletionDate = job.created
  }
  if (job.pipeline === Pipelines.Delete && job.mode === Modes.H3 &&
    job.status === Status.Finished) {
    state.latestH3DeletionDate = job.created
  }
}

const updateLatestJobsAfterDeletionReferences = (state, job) => {
  const latestDeletionDate =
    job.mode === Modes.H3 ? state.latestH3DeletionDate : state.latestSegmentDeletionDate

  // If it's a deletion job, reset and recalculate for all jobs in the dataset
  if (job.pipeline === Pipelines.Delete) {
    removeJobsByMode(state, job.mode)

    // Recalculate based on all jobs for this dataset after the latest deletion
    /* Object.values(state.entities)
      .filter(j => j.pipeline === Pipelines.Surface)
      .forEach((processingJob) => {
        console.log('>>>>>>>> update latest for refresh update')
        updateLatestJobsAfterDeletion(state, processingJob, latestDeletionDate)
      }) */
  } else {
    if (job.pipeline === Pipelines.Surface) {
      // If it's not a deletion job, check if it's the latest job after the deletion
      updateLatestJobsAfterDeletion(state, job, latestDeletionDate)
    }
  }
}

const removeJobsByMode = (state, modeToRemove) => {
  Object.keys(state.latestJobsAfterDeletionByDataset).forEach(datasetId => {
    const datasetJobs = state.latestJobsAfterDeletionByDataset[datasetId]

    if (datasetJobs && datasetJobs[modeToRemove]) {
      delete datasetJobs[modeToRemove]
    }

    if (Object.keys(datasetJobs).length === 0) {
      delete state.latestJobsAfterDeletionByDataset[datasetId]
    }
  })
}

const updateLatestJobsAfterDeletion = (state, job, latestDeletionDate) => {
  if (new Date(job.created) > new Date(latestDeletionDate)) {
    const datasetId = getDatasetId(job)
    state.latestJobsAfterDeletionByDataset[datasetId] =
      state.latestJobsAfterDeletionByDataset[datasetId] || {}

    const currentAfterDeletionJobId =
      state.latestJobsAfterDeletionByDataset[datasetId][job.mode]
    const currentAfterDeletionJob =
      currentAfterDeletionJobId ? state.entities[currentAfterDeletionJobId] : null

    // Save the job if it's the latest one created after the deletion
    if (!currentAfterDeletionJob ||
        new Date(job.created) > new Date(currentAfterDeletionJob.created)) {
      state.latestJobsAfterDeletionByDataset[datasetId][job.mode] = job.id
    }
  }
}

/**
 * Updates whether analyses for each mode are based on partially deleted measurements.
 */
const updatePartiallyDeletedAnalysisByMode = (state) => {
  const modesToCheck = [Modes.Segment, Modes.H3]

  modesToCheck.forEach((mode) => {
    // Find deletion jobs for measurements (Mode.Measurement)
    const deletionJobs = Object.values(state.entities).filter(job =>
      job.mode === Modes.Measurement && job.pipeline === Pipelines.Delete &&
      job.status === Status.Finished
    )

    // Find finished analysis jobs for the mode, after the latest deletion date
    const analysisJobs = Object.values(state.entities).filter(job => {
      const latestDeletionDate = mode === Modes.H3
        ? state.latestH3DeletionDate
        : state.latestSegmentDeletionDate
      return job.mode === mode && job.pipeline === Pipelines.Surface &&
        job.status === Status.Finished && new Date(job.created) > new Date(latestDeletionDate)
    })

    // Check if any analysis depends on a partially deleted measurement
    state.partiallyDeletedAnalysisByMode[mode] = analysisJobs.some(analysisJob => {
      return deletionJobs.some(deletionJob => {
        const sameMeasurement =
          deletionJob.deviceId === analysisJob.deviceId &&
          deletionJob.measurementId === analysisJob.measurementId
        const deletedAfterAnalysis = new Date(deletionJob.created) > new Date(analysisJob.created)
        return sameMeasurement && deletedAfterAnalysis
      })
    })
  })
}

export const getDatasetId = (job) => `${job.deviceId}:${job.measurementId}`

/**
 * Function to get the latest job after deletion by datasetId and mode.
 */
export const latestJobsAfterDeletionByDatasetId = (state, datasetId, mode) => {
  return state.jobs.latestJobsAfterDeletionByDataset[datasetId]?.[mode] || null
}

/**
 * Selector to check if a particular mode's analysis is based on partially deleted measurements.
 */
export const isAnalysisOnPartiallyDeletedMeasurementsForMode = (state, mode) => {
  return state.jobs.partiallyDeletedAnalysisByMode[mode] || false
}

export const {
  selectAll: selectAllJobs,
  selectById: selectJobsById
} = jobsAdapter.getSelectors((state) => state.jobs)

export const { addJobs } = jobsSlice.actions

export const jobsReducer = jobsSlice.reducer
export { initialState as jobsInitialState }
