import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import ControlButton from '../ControlButton'
import { Modes, Pipelines } from './datasets/DatasetsAnalysis'
import { States } from '../../reducers/datasetsView'
import { Sources } from '../constants/Sources'
import UnviewedJobsBadge from './UnviewedJobsBadge'
import { Status } from '../JobHelpers'
import { selectHasMeasurements } from '../../reducers/measurements'
import { updateUi, setVisibleLayerId } from '../../reducers/ui'
import { Views } from '../constants/Views'
import { selectHasH3 } from '../../reducers/h3'
import { selectHasSegments } from '../../reducers/segments'
import { styled } from '@mui/material/styles'
import { Colors } from '../constants/Colors'

/**
 * To switch between the different views (datasets, infrastructure)
 *
 * @author Armin Schnabel
 */
const ViewControls = ({ map }) => {
  // Stateless Hooks
  const dispatch = useDispatch()

  // Redux state
  const hasH3 = useSelector(selectHasH3)
  const hasSegments = useSelector(selectHasSegments)
  const direction = useSelector(state => state.ui.direction)
  const view = useSelector(state => state.ui.view)
  const hasMeasurements = useSelector(selectHasMeasurements)
  const datasetsView = useSelector((state) => state.datasetsView)
  const infrastructureView = useSelector((state) => state.infrastructureView)

  const infrastructureButton = (view, processedDataExists, defaultToolTip) => {
    const showBadge = view !== Views.Infrastructure
    return (
      <ButtonWithBadge>
        <ControlButton
          width='130px'
          text="Analysen"
          icon="insert_chart"
          onClick={(_) => selectView(Views.Infrastructure)}
          active={true}
          backgroundColor={view === Views.Infrastructure ? Colors.Primary : Colors.Neutral}
          disabled={!processedDataExists}
          tooltip={defaultToolTip}
        />
        {showBadge
          ? (
          <UnviewedJobsBadge
            left={'74%'}
            countFunction={(jobs) => {
              return Object.values(jobs).reduce(
                (count, job) =>
                  count +
                  (job.pipeline === Pipelines.Surface &&
                    job.mode !== Modes.SegmentHistory &&
                    job.status === Status.Finished &&
                    !('viewed' in job)),
                0
              )
            }}
          />
            )
          : (
              ''
            )}
      </ButtonWithBadge>
    )
  }

  const selectView = (newView) => {
    const previousView = view
    if (newView === previousView) {
      return
    }
    if (
      newView !== Views.Datasets &&
      newView !== Views.Infrastructure &&
      newView !== Views.Export
    ) {
      throw Error('Unknown view: ' + newView)
    }

    // Update view in Redux
    dispatch(updateUi({ view: newView }))

    switchLayers(previousView, newView)
  }

  // Switch dataset on map
  const switchLayers = (previousView, newView) => {
    const { mode, modality } = infrastructureView

    // Hide previous view
    if (previousView === Views.Datasets) hideDatasets(datasetsView, map)

    // Draw new view
    switch (newView) {
      case Views.Datasets:
        dispatch(setVisibleLayerId(map, Sources.Datasets, direction, modality))
        redrawDatasets(datasetsView, map)
        break
      case Views.Infrastructure: {
        if (mode === Modes.H3) {
          dispatch(setVisibleLayerId(map, Sources.H3, direction, modality))
        } else if (mode === Modes.Segment) {
          dispatch(setVisibleLayerId(map, Sources.Segment, direction, modality))
        }
        break
      }
      case Views.Export: {
        // We currently don't show any data there but will in the future
        dispatch(setVisibleLayerId(map, Sources.Export, direction, modality))
        break
      }
      default:
        throw new Error('Unknown view: ' + newView)
    }
  }

  // Redraw the selected elements from previous datasets view
  const redrawDatasets = (datasetsView, map) => {
    if (datasetsView.active === States.ShowDatasets && datasetsView.selected.length > 0) {
      datasetsView.selected.forEach(id => {
        // The layer might not be loaded yet.
        if (map.getLayer(id)) {
          map.setLayoutProperty(id, 'visibility', 'visible')
        }
      })
    }
  }

  // Hide the selected elements from previous datasets view
  const hideDatasets = (datasetsView, map) => {
    if (datasetsView.active === States.ShowDatasets && datasetsView.selected.length > 0) {
      datasetsView.selected.forEach(id => {
        // The layer might not be loaded yet.
        if (map.getLayer(id)) {
          map.setLayoutProperty(id, 'visibility', 'none')
        }
      })
    }
  }

  const defaultToolTip = "Informationen zu dem ausgewählen Bereich finden Sie im 'Hilfe'-" +
    'Bereich unten links.'
  const processedDataExists = hasH3 || hasSegments

  return (
    <Container>
      <ControlButton
        width='112px'
        text="Daten"
        icon="storage"
        onClick={(_) => selectView(Views.Datasets)}
        active={true}
        backgroundColor={view === Views.Datasets ? Colors.Primary : Colors.Neutral}
        tooltip={defaultToolTip}
      />

      {hasMeasurements
        ? !processedDataExists
            ? <ControlButton disabled={true} text='Beauftragen Sie eine Datensatz-Analyse' />
            : infrastructureButton(view, processedDataExists, defaultToolTip)
        : ''
      }

      <ControlButton
        width='112px'
        text="Export"
        icon="file_download"
        onClick={(_) => selectView(Views.Export)}
        active={true}
        backgroundColor={view === Views.Export ? Colors.Primary : Colors.Neutral}
        tooltip={defaultToolTip}
      />
    </Container>
  )
}

ViewControls.propTypes = {
  map: PropTypes.object
}

const Container = styled('header')({
  position: 'static',
  padding: '10px 0px 10px 0px'
})

const ButtonWithBadge = styled('header')({
  position: 'relative',
  display: 'inline'
})

export default ViewControls
