import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../resources/logo_small_rfr.svg'
import { styled } from '@mui/material/styles'

/**
 * The top bar of the dashboard with the style of RFR.
 */
const Header = ({ right }) => {
  return (
    <Container>
      <Line />

      <Center>
        <Logo src={ logo } alt='Logo' />
        <Link href='https://ready-for-robots.de'>
          Zurück zur Beteiligungsplattform
        </Link>
      </Center>

      <div style={{ position: 'absolute', top: '35px', right: '40px' }}>
        {right}
      </div>
    </Container>
  )
}

Header.propTypes = {
  right: PropTypes.object.isRequired
}

const Container = styled('div')({
  width: '100%',
  backgroundColor: 'rgb(216, 216, 216)'
})

const Line = styled('div')({
  height: '0.5em',
  background: 'linear-gradient(to right, #278a5b, #278a5b 33%, #0082c8 66%, #0082c8)',
  '@media (max-width: 1200px)': {
    height: '0.55rem'
  }
})

const Center = styled('div')({
  display: 'flex',
  alignItems: 'center', // horizontally center content
  margin: '0 auto',
  width: '100%',
  height: '88.38px',
  '@media (max-width: 768px)': {
    height: '62px'
  },
  '@media (min-width: 576px)': {
    width: '540px'
  },
  '@media (min-width: 768px)': {
    width: '720px'
  },
  '@media (min-width: 992px)': {
    width: '960px'
  },
  '@media (min-width: 1200px)': {
    width: '1140px'
  },
  '@media (min-width: 1440px)': {
    width: '1320px'
  }
})

const Logo = styled('img')({
  height: '2.3rem',
  width: 'auto',
  marginLeft: '5px',
  marginRight: '24px',
  paddingLeft: '7px',
  '@media (min-width: 576px)': {
    height: '2.3rem',
    paddingLeft: '0px'
  },
  '@media (min-width: 768px)': {
    height: '0rem'
  },
  '@media (min-width: 992px)': {
    height: '1rem'
  },
  '@media (min-width: 1200px)': {
    height: '2.65rem'
  }
})

const Link = styled('a')({
  color: 'rgba(0, 0, 0, 0.55)',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.7)'
  },
  fontSize: '15px',
  // eslint-disable-next-line max-len
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  marginTop: '1px',
  '@media (max-width: 768px)': {
    fontSize: '14px',
    textAlign: 'center',
    width: '100%'
  }
})

export default Header
