import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'

const DatasetPreloader = ({ mobileView, width }) => {
  // Redux state
  const datasetLoading = useSelector(state => state.datasetsView.datasetLoading)

  // Style calculations
  const preloaderLeftPosition = mobileView ? width / 2 : (width + 400) / 2

  return (
    <DatasetPreloaderContainer
      left={preloaderLeftPosition}
      top={window.innerHeight / 2}
    >
    {datasetLoading && (
      <CircularProgress
        size={40}
        color="success" // green
      />
    )}
    </DatasetPreloaderContainer>
  )
}

DatasetPreloader.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired
}

const DatasetPreloaderContainer = styled(Box)(({ left, top }) => ({
  zIndex: 2,
  position: 'absolute',
  left,
  top
}))

export default DatasetPreloader
