import React from 'react'
import PropTypes from 'prop-types'
import { Views } from '../../constants/Views'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'

/**
 * This function component shows a legend on the map.
 */
const Legend = ({ mobileView, zoomLevel }) => {
  // Redux state
  const view = useSelector(state => state.ui.view)

  if (view !== Views.Infrastructure) {
    return ''
  }

  return zoomLevel > 10
    ? (
      <LegendBox $left={mobileView ? '0%' : '400px'} >
        <Header>Oberflächenbeschaffenheit</Header>
        <LineWrapper>
          <Line height='0.25em' color='blue' />
          <Description>Glatt</Description>
        </LineWrapper>
        <LineWrapper>
          <Line height='0.25em' color='green' />
          <Description>Normal</Description>
        </LineWrapper>
        <LineWrapper>
          <Line height='0.25em' color='orange' />
          <Description>Mäßig</Description>
        </LineWrapper>
        <LineWrapper>
          <Line height='0.25em' color='red' />
          <Description>Rau</Description>
        </LineWrapper>
      </LegendBox>
      )
    : (
      <LegendBox $left={mobileView ? '0%' : '400px'} >
        <Header>Oberflächenbeschaffenheit</Header>
        <PointWrapper>
          <Point/>
          <Description>für Details heranzoomen</Description>
        </PointWrapper>
      </LegendBox>
      )
}

Legend.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired
}

const LegendBox = styled('div')(({ $left }) => ({
  position: 'absolute',
  width: '14.5em',
  height: '10em',
  marginLeft: '10px',
  backgroundColor: 'hsla(0, 0%, 100%, 0.75)',
  left: $left,
  bottom: '5em',
  zIndex: 3 // one below SegmentDetails (4)
}))

const Header = styled('div')({
  padding: '0.6em'
})

const Line = styled('div')(({ height, color }) => ({
  height,
  width: '4em',
  backgroundColor: color,
  display: 'inline-block',
  marginRight: '1.5em'
}))

const LineWrapper = styled('div')({
  marginLeft: '0.7em'
})

const Description = styled('div')({
  display: 'inline-block',
  fontSize: '0.9em'
})

const Point = styled('div')({
  display: 'inline-block',
  borderRadius: '50%',
  height: '1em',
  width: '1em',
  backgroundColor: 'green',
  marginLeft: '0.7em',
  marginRight: '0.7em'
})

const PointWrapper = styled('div')({
  marginLeft: '0.7em'
})

export default Legend
