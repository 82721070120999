import { updateObject } from './utils.js'
import { Modalities } from '../components/sidebar/Modality.js'
import { Modes } from '../components/sidebar/datasets/DatasetsAnalysis'
import { createSlice } from '@reduxjs/toolkit'
import { Sources } from '../components/constants/Sources.js'

/**
 * Reducer code specific to the `infrastructureView`.
 *
 * @author Armin Schnabel
 */

/**
 * The current state of this part of the app.
 *
 * e.g. which data, elements and filters are selected.
 */
const initialState = {
  // The "mode" which is currently selected: "h3" | "segment"
  // Influences which layer to set as visibleLayerId when the infrastructureView is active.
  mode: Modes.H3,

  // use the same modality filter for all modes, as probably expected by the user
  modality: Modalities.All,

  // The id of the feature currently hovered
  hoveredWayId: null,

  // The id of the feature currently clicked
  clickedWayId: null
}

const infrastructureViewSlicer = createSlice({
  name: 'infrastructureView',
  initialState,
  reducers: {
    updateInfrastructureView: (state, action) => {
      const { update, map } = action.payload
      if (!update || !map) {
        throw new Error('Missing payload attributes')
      }

      const sourceId = Sources.Segment
      if ('hoveredWayId' in update) {
        const previousHoveredWayId = state.hoveredWayId
        const hoveredWayId = update.hoveredWayId
        switchFeatureState(map, sourceId, previousHoveredWayId, hoveredWayId, 'hovered')
      }
      if ('clickedWayId' in update) {
        const previousClickedWayId = state.clickedWayId
        const clickedWayId = update.clickedWayId
        switchFeatureState(map, sourceId, previousClickedWayId, clickedWayId, 'clicked')
      }

      return updateObject(state, update)
    }
  }
})

const switchFeatureState = (map, sourceId, previousWayId, newWayId, propertyName) => {
  if (previousWayId !== newWayId) {
    if (previousWayId !== null) {
      map.setFeatureState({ source: sourceId, id: previousWayId }, { [propertyName]: false })
    }
    if (newWayId !== null) {
      map.setFeatureState({ source: sourceId, id: newWayId }, { [propertyName]: true })
    }
  }
}

export const { updateInfrastructureView } = infrastructureViewSlicer.actions

export const infrastructureViewReducer = infrastructureViewSlicer.reducer
export { initialState as infrastructureViewInitialState }
