import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { MenuItem, Menu, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import ControlButton from '../../ControlButton'
import { modalityToGerman, Modalities } from '../Modality'
import { FILTER_MODALITY, FILTER_FORWARD, FILTER_BACKWARD } from '../../map/MapBoxFilters'
import { Sources } from '../../constants/Sources'
import { Directions } from '../../constants/Directions'
import { Modes, Pipelines } from '../datasets/DatasetsAnalysis'
import { Views } from '../../constants/Views'
import AnalysisRemoval from './AnalysisRemoval'
import { Status } from '../../JobHelpers'
import UnviewedJobsBadge from '../UnviewedJobsBadge'
import { setVisibleLayerId } from '../../../reducers/ui'
import { updateInfrastructureView } from '../../../reducers/infrastructureView'
import { selectHasH3 } from '../../../reducers/h3'
import { selectHasSegments } from '../../../reducers/segments'
import { Colors } from '../../constants/Colors'
// import { useUpdateFinishedJobMutation } from '../../../api/apiSlice'

/**
 * Control elements shown in the `Views.Infrastructure` mode where the user can view and
 * filter the data analysis results.
 *
 * @author Armin Schnabel
 */
const InfrastructureView = ({ logout, map, viewControls }) => {
  // Stateless Hooks
  const dispatch = useDispatch()

  // Local State
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  // Redux State
  const hasH3 = useSelector(selectHasH3)
  const hasSegments = useSelector(selectHasSegments)
  const mode = useSelector(state => state.infrastructureView.mode)
  const modality = useSelector(state => state.infrastructureView.modality)
  const view = useSelector(state => state.ui.view)
  const direction = useSelector(state => state.ui.direction)
  const visibleLayerId = useSelector(state => state.ui.visibleLayerId)

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const selectMode = (map, newMode) => {
    if (newMode === mode) return
    if (newMode === '' || (newMode !== Modes.H3 && newMode !== Modes.Segment)) {
      throw new Error('Unknown mode: ' + newMode)
    }

    dispatch(updateInfrastructureView({ update: { mode: newMode }, map }))

    // Switch data on map
    const layerId = newMode === Modes.Segment ? Sources.Segment : Sources.H3
    const newDirection = direction === Directions.Unknown ? Directions.Forward : direction
    dispatch(setVisibleLayerId(map, layerId, newDirection, modality))

    updateFilters(modality, newMode, map, direction)
  }

  const modeSelection = () => (
    <div>
      <Label>Darstellung</Label>
      <br />
      <ButtonWithBadge>
        <ControlButton
          text="Segmente"
          onClick={() => selectMode(map, Modes.Segment)}
          active={true}
          backgroundColor={mode === Modes.Segment ? Colors.Primary : Colors.Neutral}
        />
        <UnviewedJobsBadge
          left={mode !== Modes.Segment ? '36%' : '85%'}
          countFunction={(jobs) => {
            const modeToFilter = mode === Modes.Segment ? Modes.H3 : Modes.Segment
            // Count the number of jobs satisfying the predicate.
            return Object.values(jobs).reduce((count, item) =>
              count + (
                item.pipeline === Pipelines.Surface &&
                item.mode === modeToFilter &&
                item.status === Status.Finished &&
                !('viewed' in item)
              ), 0)
          }}
        />
        <ControlButton
          text="Heatmap"
          onClick={() => selectMode(map, Modes.H3)}
          active={true}
          backgroundColor={mode === Modes.H3 ? Colors.Primary : Colors.Neutral}
        />
      </ButtonWithBadge>
    </div>
  )

  const detectOrphanSurfaces = () => {
    const hasDataForMode = mode === Modes.H3 ? hasH3 : hasSegments
    return hasDataForMode ? <AnalysisRemoval mode={mode} logout={logout} map={map} /> : ''
  }

  const directionFilter = () => {
    return (mode === Modes.Segment)
      ? (
        <div>
          <label>Dargestellte Fahrtrichtung</label><br />
          <ControlButton
            text="Orientierungs-"
            onClick={() =>
              dispatch(setVisibleLayerId(map, visibleLayerId, Directions.Forward, modality))
            }
            active={true}
            backgroundColor={direction === Directions.Forward ? Colors.Primary : Colors.Neutral}
            disabled={mode !== Modes.Segment}
          />
          <ControlButton
            text="Gegenrichtung"
            onClick={() =>
              dispatch(setVisibleLayerId(map, visibleLayerId, Directions.Backward, modality))
            }
            active={true}
            backgroundColor={direction === Directions.Backward ? Colors.Primary : Colors.Neutral}
            disabled={mode !== Modes.Segment}
          />
        </div>
        )
      : ''
  }

  const modalityFilter = () => (
    <div>
      <label> Modalität </label><br />
      <ButtonWithDropdown>
        <ControlButton
          text={modalityToGerman(modality)}
          onClick={handleOpenMenu}
          active={modality !== Modalities.All}
        />
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
        >
          {Object.values(Modalities).map((modalityOption) => (
            <MenuItem
              key={modalityOption}
              onClick={() => {
                selectModality(map, modalityOption, mode, modality, direction)
                handleCloseMenu()
              }}
            >
              {modalityToGerman(modalityOption)}
            </MenuItem>
          ))}
        </Menu>
      </ButtonWithDropdown>
    </div>
  )

  // Untested, disabled functionality
  // const [updateFinishedJob, result] = useUpdateFinishedJobMutation()

  // Untested, disabled functionality
  // const markJobsAsSeen = async (mode, jobs) => {
  //   const unseenFinishedSurfaceJobs = jobs.filter(job =>
  //     job.pipeline === Pipelines.Surface &&
  //     job.status === Status.Finished &&
  //     job.mode === mode &&
  //     !('viewed' in job))
  //   if (unseenFinishedSurfaceJobs.length !== 0) {
  //     await Promise.all(unseenFinishedSurfaceJobs.map(async job => {
  //       const body = [{ op: 'replace', path: 'viewed', value: new Date().toISOString() }]
  //       return updateFinishedJob({job.id, body})
  //     }))
  //   }
  // }

  const selectModality = (map, newModality, mode, modality, direction) => {
    if (newModality !== modality) {
      updateFilters(newModality, mode, map, direction)
      dispatch(updateInfrastructureView({ update: { modality: newModality }, map }))
    }
  }

  // Untested, disabled functionality
  /* if (view === Views.Infrastructure) {
     markJobsAsSeen(mode, allJobs)
   } */

  return (
    <Container $view={view}>
      <Actions>
        {viewControls}
        {modeSelection()}
        {detectOrphanSurfaces()}
        {directionFilter()}
        {modalityFilter()}
        {/* modeSelection(map, mode)}
        {detectOrphanSurfaces(map, logout, mode, view, allJobs)}
        {directionFilter(map, mode, modality, direction, visibleLayerId)}
        {modalityFilter(modality, direction) */}
      </Actions>
    </Container>
  )
}

const updateFilters = (modality, mode, map, direction) => {
  const MODALITY_FILTER = FILTER_MODALITY(modality)
  if (mode === Modes.Segment) {
    const COMBINED_FILTER = direction
      ? ['all', MODALITY_FILTER, FILTER_FORWARD]
      : ['all', MODALITY_FILTER, FILTER_BACKWARD]
    map.setFilter(direction === Directions.Forward
      ? Sources.ForwardArrows
      : Sources.BackwardArrows, MODALITY_FILTER)
    map.setFilter(Sources.Segment, COMBINED_FILTER)
    map.setFilter(Sources.Segment + '_overview', COMBINED_FILTER)
  } else {
    map.setFilter(Sources.H3, MODALITY_FILTER)
    map.setFilter(Sources.H3 + '_overview', MODALITY_FILTER)
  }
}

InfrastructureView.propTypes = {
  logout: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
  viewControls: PropTypes.node.isRequired
}

const Container = styled('div')(({ $view }) => ({
  display: $view === Views.Infrastructure ? 'block' : 'none',
  height: '100%',
  overflowY: 'auto'
}))

const Actions = styled(Box)({
  padding: '0px 10px 10px 10px'
})

const Label = styled('div')({
  // To fix the missalignment between the first button in comparison with Datasets-/ExportView
  marginBottom: '-23px'
})

const ButtonWithBadge = styled('header')({
  position: 'relative',
  display: 'inline'
})

const ButtonWithDropdown = styled('div')({
  display: 'inline-block',
  position: 'relative'
})

export default InfrastructureView
