import React from 'react'
import { Skeleton, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

/**
 * This component displays placeholder "skeleton" while the map is loading
 */
const SidebarSkeleton = () => {
  return (<Container>
    <SkeletonWrapper>
      <Skeleton variant="rectangular" sx={{ margin: '10px 5px 0px 0px' }} width={112} height={32} />
      <Skeleton variant="rectangular" sx={{ margin: '10px 5px 0px 0px' }} width={130} height={32} />
      <Skeleton variant="rectangular" sx={{ margin: '10px 5px 0px 0px' }} width={112} height={32} />
    </SkeletonWrapper>

    <FilterLabelContainer>Filter</FilterLabelContainer>

    <FilterSkeleton>
      <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width={60} height={32} />
      <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width={110} height={32} />
      <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width={45} height={32} />
    </FilterSkeleton>

    <ActionsLabelContainer>Aktionen</ActionsLabelContainer>

    <Skeleton variant="rectangular" sx={{ margin: '0px 5px 0px 0px' }} width={176} height={32} />

    <AnalysisLabelContainer>Straßenqualität analysieren</AnalysisLabelContainer>

    <AnalysisSkeleton>
      <Skeleton variant="rectangular" sx={{ margin: '0px 5px 35px 0px' }} width={125} height={32} />
      <Skeleton variant="rectangular" sx={{ margin: '0px 5px 35px 0px' }} width={125} height={32} />
    </AnalysisSkeleton>

    Messungen
    <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width="100%" height={45} />
    <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width="100%" height={45} />
    <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width="100%" height={45} />
    <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width="100%" height={45} />
    <Skeleton variant="rectangular" sx={{ margin: '0px 5px 5px 0px' }} width="100%" height={45} />
  </Container>)
}

const Container = styled(Box)({
  display: 'flex',
  padding: '0px 10px 0px 10px',
  flexDirection: 'column',
  alignItems: 'stretch',
  position: 'static',
  height: '100%'
})

const SkeletonWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
})

const FilterLabelContainer = styled(Box)({
  margin: '15px 0px -5px 0px'
})

const FilterSkeleton = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px'
})

const ActionsLabelContainer = styled(Box)({
  margin: '0px 0px 0px 0px'
})

const AnalysisSkeleton = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '5px'
})

const AnalysisLabelContainer = styled(Box)({
  margin: '3px 0px -5px 0px'
})

export default SidebarSkeleton
