import React from 'react'
import PropTypes from 'prop-types'
import ControlButton from '../ControlButton'

/**
 * A button which allows the user to log out.
 *
 * @author Armin Schnabel
 */
const LogoutButton = ({ logout }) => {
  return (
    <ControlButton
      text="Ausloggen"
      onClick={ logout }
      active={false}
      width='130px'
      icon="power_settings_new"
      tooltip="Abmelden"
    />
  )
}

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired
}

export default LogoutButton
