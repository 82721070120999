import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { LinearProgress } from '@mui/material'
import GenericIcon from '../GenericIcon'
import { Status } from '../../JobHelpers'

/**
 * The default icon color.
 */
const iconColor = '#c1c1c1'

/**
 * Formats a duration in milliseconds into the human readable format `mm:ss`.
 *
 * @param {*} milliseconds The duration to format in milliseconds.
 * @returns The formatted duration.
 */
const humanReadable = (milliseconds) => {
  const seconds = Math.round(milliseconds / 1000)
  const minutes = Math.round(seconds / 60)
  const hours = Math.round(minutes / 60)
  if (hours > 0) {
    return '~ ' + hours + ' h ' + minutes + ' min'
  }
  if (minutes > 0) {
    return '~ ' + minutes + ' min'
  }
  if (seconds > 0) {
    return '< 1 min'
  }
  return ''
}

/**
 * A jobs progress property can be `undefined` if it's not yet running or already finished.
 */
const progress = (job) => {
  return job.progress !== undefined ? job.progress : job.status === Status.Finished ? 1 : 0
}

/**
 * This function component handles the progress of submitted jobs.
 */
const JobsProgress = ({ mode, selected, checkable, jobs, tooltip, status }) => {
  /**
   * `True` if the job is currently running.
   */
  const running = status === Status.Running

  /**
   * Icon which indicates that a newly created job exists which has no progress yet.
   */
  const pendingActionIcon = <GenericIcon
      margin='8px 0px 0px 0px'
      tooltip={tooltip + (running
        ? ' - Verarbeitung läuft (kann je nach Last mehrere Stunden benötigen)'
        : ' - Verarbeitung eingereiht')}
      icon={running ? 'hourglass_top' : 'pending_actions'}
      float="right"
      color={iconColor} />

  /**
   * Merges the progress in case multiple sub-jobs are injected as `jobs` parameter.
   */
  const combinedProgress = jobs.reduce((mergedProgress, job) =>
    mergedProgress + (progress(job) / jobs.length), 0)

  /**
   * The remaining duration in milliseconds.
   *
   * In case multiple sub-jobs are injected via `jobs` property, the maximum duration is returned.
   */
  const remainingMillis = Math.max(...jobs.map(j => j.remaining !== undefined ? j.remaining : 0))

  /**
   * Return progress bar if a progress is already reported by the API, or a placehole icon instead.
   */
  return combinedProgress !== 0 && running
    ? (
      <Container>
        <ProgressBar title={mode}>
          <LinearProgress
            variant="determinate"
            value={combinedProgress * 100}
            sx={{
              backgroundColor: 'lightgrey',
              '& .MuiLinearProgress-bar': {
                backgroundColor: selected && !checkable ? '#FFFFFF' : '#3F8730'
              }
            }}
          />
        </ProgressBar>
        { /* remaining property can be undefined if a job is finished or not yet started */ }
        <TextContent>{ humanReadable(remainingMillis)}</TextContent>
      </Container>
      )
    : (pendingActionIcon)
}

JobsProgress.propTypes = {
  mode: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  checkable: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  jobs: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired
}

const Container = styled('div')({
  clear: 'both',
  display: 'flex',
  flexWrap: 'wrap',
  order: 1
})

const ProgressBar = styled('div')({
  width: '210px',
  marginRight: '1em',
  marginTop: '5px',
  flex: 1
})

const TextContent = styled('div')({
  color: '#c1c1c1',
  float: 'right',
  fontSize: '0.8em',
  marginRight: '1.3em',
  marginTop: '-4px'
})

/* const PreloaderContainer = styled('div')({
  transform: 'scale(0.4)',
  float: 'right'
}) */

export default JobsProgress
