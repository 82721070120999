import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Box, Typography } from '@mui/material'
import GenericIcon from '../GenericIcon'
import Jobs from './Jobs'
import CustomCollectionItem from '../CustomCollectionItem'
import modalityIcon from '../Modality'
import { selectDataset, States, unselectDataset } from '../../../reducers/datasetsView'
import { useDispatch, useSelector } from 'react-redux'
import { Modes } from './DatasetsAnalysis'
import { latestJobsAfterDeletionByDatasetId, selectJobsById } from '../../../reducers/jobs'
import { styled } from '@mui/material/styles'

const DatasetItem = ({
  dataset,
  isSelected,
  selectable,
  onDatasetClicked,
  addTagsToMeasurementsCallBack,
  active
}) => {
  // Stateless Hooks
  const dispatch = useDispatch()

  // Redux State
  const segmentJobId = useSelector(state =>
    latestJobsAfterDeletionByDatasetId(state, dataset.id, Modes.Segment))
  const historyJobId = useSelector(state =>
    latestJobsAfterDeletionByDatasetId(state, dataset.id, Modes.SegmentHistory))
  const h3JobId = useSelector(state =>
    latestJobsAfterDeletionByDatasetId(state, dataset.id, Modes.H3))
  const mapMatchingJobId = useSelector(state =>
    latestJobsAfterDeletionByDatasetId(state, dataset.id, Modes.None))
  const latestSegmentJob = useSelector(state =>
    segmentJobId ? selectJobsById(state, segmentJobId) : null)
  const latestSegmentHistoryJob = useSelector(state =>
    historyJobId ? selectJobsById(state, historyJobId) : null)
  const latestH3Job = useSelector(state =>
    h3JobId ? selectJobsById(state, h3JobId) : null)
  const latestMapMatchingJob = useSelector(state =>
    mapMatchingJobId ? selectJobsById(state, mapMatchingJobId) : null)

  const nonRunningNonSegmentJobs =
      [latestH3Job, latestMapMatchingJob].filter(j => j && j.status !== 'RUNNING')
  const runningNonSegmentJobs =
      [latestH3Job, latestMapMatchingJob].filter(j => j && j.status === 'RUNNING')
  const segmentJobs =
      [latestSegmentJob, latestSegmentHistoryJob].filter(j => j)

  const checkable = active === States.SelectAnalyzeDatasets ||
    active === States.SelectRemoveDatasets
  const selectedInShowDatasets = isSelected && active === States.ShowDatasets
  const formattedLength = Math.round((Math.round(dataset.length / 10) / 10)) / 10 // e.g. 0.4 km
  const disabled =
    active === States.ChooseAnalyzeMode ||
    active === States.DeleteDatasets ||
    active === States.SubmittingAnalyzeJobs

  /**
   * Generates a text which represents the measurement duration.
   *
   * @param {*} dataset The dataset to generate the text for.
   * @returns The generated text.
   */
  const showMeasuredDuration = (dataset) => {
    const startDate = new Date(dataset.startTimestamp)
    const endDate = new Date(dataset.endTimestamp)
    const uploadDate = new Date(dataset.uploadDate)

    // When no GNSS data was collected
    if (dataset.startTimestamp === undefined) {
      return ('Uploaded: ' + uploadDate.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }))
    }

    // legal duration
    if (startDate <= endDate) {
      const startDateString = startDate.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
      return startDateString
    }

    throw Error('Unexpected dataset duration')
  }

  return (
    <CustomCollectionItem
      active={selectedInShowDatasets}
      selectable={selectable}
      checkable={checkable}
      disabled={ disabled }
      onClick={(e) => onDatasetClicked(e, dataset)}
      key={dataset.id}
      item={dataset}
      selected={isSelected}
      // We inject this function so CustomCollectionItem can be Pure Component
      addSelected={(id) => dispatch(selectDataset(id))}
      removeSelected={(id) => dispatch(unselectDataset(id))}
      addTagsToMeasurements={addTagsToMeasurementsCallBack}
      text={
        <Content>
          {modalityIcon(
            dataset.modality,
            'scale(1.0)',
            '-9px',
            '7px 0px 0px 0px',
            selectedInShowDatasets ? 'white' : '#c1c1c1'
          )}
          <Text sx={{ color: selectedInShowDatasets ? 'white' : 'rgb(70, 70, 70)' }}>
            {showMeasuredDuration(dataset)} ({formattedLength} km)
          </Text>

          { /* Finished non-segment jobs */ }
          <Icons>
            { // Mark jobs of measurements which are not deserialized (yet)
              dataset.deserialized || dataset.length === 0
                ? null
                : (
                <GenericIcon
                  margin='8px 0px 0px 0px'
                  tooltip="Nicht zur Anzeige bereit"
                  icon="priority_high"
                  color={selectedInShowDatasets ? 'white' : '#c1c1c1'}
                />
                  )}

            { // Mark jobs of measurements in a unsupported file format version
              // backend only suports TRANSFER_FILE_FORMAT_VERSION = 3
              dataset.formatVersion !== 3
                ? (<GenericIcon
                    margin='8px 0px 0px 0px'
                    tooltip={'Datensatzversion nicht unterstützt: ' + dataset.formatVersion}
                    icon="priority_high"
                    color={selectedInShowDatasets ? 'white' : '#c1c1c1'} />
                  )
                : null}

            <Jobs selected={isSelected} checkable={checkable} jobs={ nonRunningNonSegmentJobs } />
          </Icons>

          { /* Segment jobs (inject segment and history job for the job progress calculation) */ }
          <Jobs selected={isSelected} checkable={checkable} jobs={ segmentJobs } />
          { /* Running non-segment jobs */ }
          <Jobs selected={isSelected} checkable={checkable} jobs={ runningNonSegmentJobs } />

          {active === States.DeleteDatasets && isSelected && (
            <DeletionJob>
              <CircularProgress size={20} color="error" />
            </DeletionJob>
          )}

          {/* TODO [RFR-301]:
          const setTagsToMeasurements = dispatch(setTagsToMeasurements())
          <DatasetTags dataset={dataset}
                       measurement={measurementsById(dataset.id)}
                       active={active}
                       setTagsToMeasurements={setTagsToMeasurements}
                       logout={logout}/> */}
        </Content>
      }
    />
  )
}

DatasetItem.propTypes = {
  dataset: PropTypes.object.isRequired,
  selectable: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onDatasetClicked: PropTypes.func.isRequired,
  addTagsToMeasurementsCallBack: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired
}

const Content = styled(Box)({
  width: '100%',
  paddingLeft: '8px'
})

const Text = styled(Typography)({
  float: 'left',
  fontSize: '14.5px',
  marginTop: '6px',
  fontFamily: 'Helvetica, Arial, sans-serif',
  userSelect: 'none'
})

const Icons = styled(Box)({
  height: '32.39px', // fix alignment of spinning icon when only spinning icon is shown
  float: 'right'
})

const DeletionJob = styled(Box)({
  transform: 'scale(0.5)',
  float: 'right'
})

export default DatasetItem
