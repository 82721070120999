import { combineReducers } from '@reduxjs/toolkit'
import { uiReducer } from './ui.js'
import { h3Reducer } from './h3.js'
import { measurementsReducer } from './measurements.js'
import { jobsReducer } from './jobs.js'
import { segmentsReducer } from './segments.js'
import { datasetsViewReducer } from './datasetsView.js'
import { infrastructureViewReducer } from './infrastructureView.js'
import { exportViewReducer } from './exportView.js'
import { apiSlice } from '../api/apiSlice'

/**
 * This file contains the handler for the entire app state.
 *
 * The reducers are structured like recommended here:
 * https://redux.js.org/usage/structuring-reducers/refactoring-reducer-example
 *
 * The state is not too deeply nested as recommended:
 * https://stackoverflow.com/a/51136076/5815054
 *
 * @author Armin Schnabel
 */

/**
 * The handler for the entire app state.
 *
 * Accepts actions and executes reducers based on the action type, which alter the Redux state.
 */
const rootReducer = combineReducers({
  // Domain data (data returned by the API)
  h3: h3Reducer,
  segments: segmentsReducer,
  measurements: measurementsReducer,
  jobs: jobsReducer,

  // App state
  datasetsView: datasetsViewReducer,
  infrastructureView: infrastructureViewReducer,
  exportView: exportViewReducer,

  // UI state
  ui: uiReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export default rootReducer
