import { Modes, Pipelines } from './sidebar/datasets/DatasetsAnalysis'

/**
 * Commonly used functions helping with job execution process.
 */

export const Status = {
  Failed: 'FAILED',
  Finished: 'FINISHED',
  Unprocessable: 'UNPROCESSABLE',
  Unknown: 'UNKNOWN',
  Created: 'CREATED',
  Running: 'RUNNING',
  Canceled: 'CANCELED', // currently not expected
  Suspended: 'SUSPENDED', // currently not expected
  Rotated: 'ROTATED', // Status only used in web-app, to identifiy "UNPROCESSABLE due to rotation"
  Filtered: 'FILTERED' // Status only used in web-app to identify when all locations were filtered
}

/**
 * Checks if a status is considered "final" (i.e. not expected to change its state automatically).
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered final.
 */
export const isStatusFinal = (status) => {
  return (isStatusUnsuccessful(status) || isStatusSuccessful(status))
}

/**
 * Checks if a status is considered "non final" (i.e. expected to change its state automatically).
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered final.
 */
export const isStatusOngoing = (status) => {
  return status === Status.Created || status === Status.Running
}

/**
 * Checks if a status is considered "final" but not successful.
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered unsuccessful.
 */
export const isStatusSuccessful = (status) => {
  return (status === Status.Finished)
}

/**
 * Checks if a status is considered "final" but not successful.
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered unsuccessful.
 */
export const isStatusUnsuccessful = (status) => {
  return (status === Status.Failed ||
    status === Status.Unprocessable ||
    status === Status.Unknown)
}

/**
 * Only returns the jobs which are not considered `final`, i.e. created/running jobs.
 *
 * @param {*} jobsIds The jobs to filter.
 * @returns The filtered jobs.
 */
export const filterOngoingJobs = (jobs) => {
  return jobs.filter(j => isStatusOngoing(j.status))
}

/**
   * Creates a timer with a specified delay.
   *
   * @params ms The delay duration in ms.
   * @returns A `Promise` that can be awaited for the delay.
   */
export const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Filters a sub-set ob jobs (e.g. all jobs of a dataset).
 *
 * For each job type (segment, segmentHistory, h3, mapMatching), only the latest jobs are returned.
 * All jobs are collected into a single array.
 *
 * @params jobs The jobs to be filtered.
 * @returns An array of the latest jobs across all types.
 */
export const filterLatestJobs = (jobs) => {
  const latestSegmentJob =
    findLatestJobByType(jobs, Pipelines.Surface, Modes.Segment)
  const latestSegmentHistoryJob =
    findLatestJobByType(jobs, Pipelines.Surface, Modes.SegmentHistory)
  const latestH3Job =
    findLatestJobByType(jobs, Pipelines.Surface, Modes.H3)
  const latestMapMatchingJob =
    findLatestJobByType(jobs, Pipelines.MapMatching, Modes.None)

  // Collect all latest jobs in an array and filter out any undefined jobs
  const latestJobs = [
    latestSegmentJob,
    latestSegmentHistoryJob,
    latestH3Job,
    latestMapMatchingJob
  ].filter(job => job !== null)

  return latestJobs
}

/**
 * Helper function to find the latest job by pipeline and mode.
 */
const findLatestJobByType = (jobs, pipeline, mode) => {
  const filteredJobs = jobs.filter(j =>
    j.pipeline === pipeline &&
    j.mode === mode // &&
    // j.status === Status.Finished
  )

  return findLatestJob(filteredJobs)
}

/**
 * Helper function to find the latest processed job date.
 */
const findLatestJob = (jobs) => {
  if (jobs.length === 0) return null
  const latestJob = jobs.reduce((prevJob, currentJob) => {
    const prevDate = new Date(prevJob.created)
    const currentDate = new Date(currentJob.created)
    return prevDate < currentDate ? currentJob : prevJob
  }, jobs[0])

  return latestJob
}
